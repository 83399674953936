import React from 'react';
import { FaInstagram, FaFacebook, FaTwitter, FaTiktok } from 'react-icons/fa'; // Assuming the use of these icons
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gradient-to-b from-blue-900 to-blue-800 text-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-4">
          <div className="col-span-1">
            <h3 className="text-lg font-medium">Follow Us</h3>
            <ul className="mt-4 space-y-2">
              <li>
                <a href="https://www.instagram.com/mentomentiofficial/" target="_blank" rel="noopener noreferrer" className="hover:text-gray-400">
                  <FaInstagram /> Instagram
                </a>
              </li>
              {/* Uncomment or add other social media links here as needed */}
            </ul>
          </div>
          <div className="col-span-1">
            <h3 className="text-lg font-medium">Contact</h3>
            <ul className="mt-4 space-y-2">
              <li>Email: <a href="mailto:mentomenti.team@gmail.com" className="font-bold underline">mentomenti.team@gmail.com</a></li>
            </ul>
          </div>
          <div className="col-span-2">
            <h3 className="text-lg font-medium">Mailing list (BETA)</h3>
            <p className="mt-4 text-gray-400">
              MentoMenti is always improving. Sign up to our Mailing list for the latest updates!
            </p>
            <div className="mt-4 flex">
              <input
                type="email"
                className="py-2 px-3 rounded-l-md flex-grow border-gray-800 focus:ring-gray-900 focus:border-gray-900"
                placeholder="Enter your email"
              />
              <button className="bg-[#334eaa] py-2 px-4 rounded-r-md hover:bg-[#1E90FF]">
                Subscribe
              </button>
            </div>
          </div>
        </div>
        <div className="mt-8 border-t border-gray-800 pt-8">
          <p className="text-gray-400">
            &copy; {new Date().getFullYear()} MentoMenti. All rights reserved.
            <br />
            <Link to="/terms" className="text-white underline">View our Policies</Link> {/* Link to internal policies page */}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
