import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import MenteeNavBar from './Components/MenteeNavbar'; 

const MenteeDashboard = () => {
  const [error, setError] = useState("");
  const { currentUser, logout } = useAuth();
  const [menteeData, setMenteeData] = useState({
    name: 'Loading...',
    bio: 'Loading...',
    school: 'Loading...',
    grade: 'Loading...',
    credentials: 'Loading...',
    interests: [],
    profilePicture: 'https://via.placeholder.com/150' 
  });

  useEffect(() => {
    async function fetchMenteeData() {
        try {
            const docRef = doc(db, "mentees", currentUser.uid);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setMenteeData(docSnap.data());
            } else {
                console.log("No such mentee!");
            }
        } catch (error) {
            console.error("Error fetching mentee data: ", error);
        }
    }

    if (currentUser) {
        fetchMenteeData();
    }
  }, [currentUser]);

  async function handleLogout() {
    setError('');

    try {
      await logout();
      // Consider redirecting to the home or login page after logout
    } catch {
      setError('Failed to logout');
    }
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-500 to-indigo-600">
      <MenteeNavBar/>
      <div className="container mx-auto p-8">
        <div className="bg-white shadow-xl rounded-xl p-6 mb-6 overflow-hidden">
          <div className="flex items-center mb-4">
            <img
              src={menteeData.profilePicture || 'https://via.placeholder.com/150'}
              alt={menteeData.name}
              className="w-32 h-32 rounded-full mr-6 border-4 border-blue-200"
            />
            <div>
              <h1 className="text-4xl font-bold text-blue-700 mb-2">{menteeData.name}</h1>
              <p className="text-gray-800">Grade Level: {menteeData.grade}</p>
              <p className="text-gray-800">School: {menteeData.school}</p>
              <p className="text-gray-800 italic">"{menteeData.bio}"</p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-6">
          <div className="bg-white shadow-xl rounded-xl p-6">
            <h2 className="text-2xl font-bold text-indigo-600 mb-4">Interests</h2>
            <ul className="list-disc list-inside text-gray-800">
              {menteeData.interests.map((interest, index) => (
                <li key={index}>{interest}</li>
              ))}
            </ul>
          </div>

          <div className="bg-white shadow-xl rounded-xl p-6">
            <h2 className="text-2xl font-bold text-indigo-600 mb-4">My Mentors</h2>
            {/* Will dynamically list mentors here if available */}
            <div className='pt-5  text-center'>
            <Link to="/findmentors" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-full text-lg transition duration-300 ease-in-out transform hover:scale-105">
      Find Mentors
    </Link>
            </div>

          </div>
        </div>

        <div className="text-center">
          <button onClick={handleLogout} className="bg-red-600 hover:bg-red-800 text-white font-bold py-2 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105">
            Log Out
          </button>
        </div>
      </div>
    </div>
  );
};

export default MenteeDashboard;
