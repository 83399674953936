import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from './Components/NavBar'; // Ensure your NavBar component is correctly imported

const PoliciesPage = () => {
    return (
        <div className="min-h-screen bg-gradient-to-b from-blue-400 to-blue-600">
            <NavBar />
            <div className="container mx-auto p-8">
                <h1 className="text-4xl font-bold text-white text-center mb-6">MentoMenti Policies</h1>
                <div className="bg-white rounded-lg shadow-lg p-6">
                    <div className="mb-6">
                        <h2 className="text-3xl font-semibold">Complete Policies Document</h2>
                        <p>For a detailed view of all our policies, including Terms and Conditions, Privacy Policy, Honor Code, and Disclaimer, please refer to our complete policies document.</p>
                        <a href="https://docs.google.com/document/d/e/2PACX-1vSZYK0bmn8IizUjQPNGjwz2-4rhnPjScLvHIkVoNxnzqYR9o6j2I-ddsUMJfwqbYa2UKgOKt7ZlkyCM/pub" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">Read Full Policies</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PoliciesPage;
