import React from 'react';
import MentorNavBar from './Components/MentorNavBar'; 

const MentorHelpPage = () => {
  return (
    <div className="bg-gradient-to-b from-cyan-500 to-blue-700 min-h-screen">
      <MentorNavBar />
      <div className="container mx-auto text-white p-8">
        <h1 className="text-5xl font-bold text-center mb-10">Mentor Help Center</h1>

        <div className="space-y-10">
          {/* Getting Started Section */}
          <section className="bg-white bg-opacity-20 backdrop-blur-md p-6 rounded-lg shadow-xl">
            <h2 className="text-4xl font-semibold mb-4">Getting Started</h2>
            <p className="text-xl">
              Welcome to MentoMenti! We're thrilled to have you join our community of mentors. Here, you'll find resources and tips to get started on your mentoring journey.
            </p>
          </section>

          {/* How to Be an Effective Mentor */}
          <section className="bg-white bg-opacity-20 backdrop-blur-md p-6 rounded-lg shadow-xl">
            <h2 className="text-4xl font-semibold mb-4">How to Be an Effective Mentor</h2>
            <p className="text-xl">
              Being an effective mentor involves more than just sharing knowledge. Discover strategies for inspiring and empowering your mentees.
            </p>
          </section>

          {/* Managing Mentoring Sessions */}
          <section className="bg-white bg-opacity-20 backdrop-blur-md p-6 rounded-lg shadow-xl">
            <h2 className="text-4xl font-semibold mb-4">Managing Mentoring Sessions</h2>
            <p className="text-xl">
              Learn how to schedule, organize, and conduct productive mentoring sessions with your mentees.
            </p>
          </section>

          {/* FAQs Section */}
          <section className="bg-white bg-opacity-20 backdrop-blur-md p-6 rounded-lg shadow-xl">
            <h2 className="text-4xl font-semibold mb-4">Frequently Asked Questions</h2>
            <div className="space-y-4">
              <div>
                <h3 className="text-2xl font-semibold">How do I connect with mentees?</h3>
                <p>Explore our platform to find mentees seeking guidance in your area of expertise. You can reach out directly through their profile.</p>
              </div>
              {/* Add more FAQs as needed */}
            </div>
          </section>

          {/* Additional Support */}
          <section className="bg-white bg-opacity-20 backdrop-blur-md p-6 rounded-lg shadow-xl">
            <h2 className="text-4xl font-semibold mb-4">Need More Help?</h2>
            <p className="text-xl">
              If you have further questions or need additional support, don't hesitate to contact us.
            </p>
            <a href="/mentorcontact" className="inline-block mt-4 bg-blue-600 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded">
              Contact Support
            </a>
          </section>
        </div>
      </div>
    </div>
  );
};

export default MentorHelpPage;
