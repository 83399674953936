import React, { useRef, useState } from 'react';
import { useAuth } from '../../../context/AuthContext';
import { Link } from 'react-router-dom';

export default function PasswordReset() {
  const emailRef = useRef();
  const { resetPassword } = useAuth(); 
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setMessage('');
      setError('');
      setLoading(true);
      await resetPassword(emailRef.current.value);
      setMessage('Check your inbox for further instructions');
    } catch {
      setError('Failed to reset password');
    }

    setLoading(false);
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-blue-400 to-blue-600">
      <div className="w-full max-w-md">
        <div className="bg-white p-8 rounded shadow-lg">
          <h2 className="text-3xl font-semibold mb-4 text-center">Password Reset</h2>
          {error && <div className="bg-red-100 text-red-500 mb-4 p-3 rounded">{error}</div>}
          {message && <div className="bg-green-100 text-green-500 mb-4 p-3 rounded">{message}</div>}
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-700 font-bold mb-2">
                Email
              </label>
              <input
                type="email"
                id="email"
                ref={emailRef}
                required
                className="border border-gray-300 rounded px-4 py-2 w-full focus:outline-none focus:ring focus:border-blue-400"
              />
            </div>
            <button
              disabled={loading}
              className="bg-blue-500 text-white rounded px-4 py-2 w-full hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-400"
            >
              Reset Password
            </button>
          </form>
          <div className="text-center mt-4">
            <Link to="/login" className="text-blue-500 hover:underline">Login</Link>
          </div>
          <div className="text-center mt-2">
            Need an account? <Link to="/signup" className="text-blue-500 hover:underline">Sign Up</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
