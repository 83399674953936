import React, {useRef} from 'react'
import About from './About';
import Hero from './Hero';
import MailList from './MailList';
import NavbarTest from './Navbar'
import Footer from '../Components/Footer';
import NavBar from '../Components/NavBar';
import ReasonsDisplay from './ReasonsDisplay';
import MentorReasonsSection from './MentorReasons';

export default function Home() {
  const aboutRef = useRef(null); // Create a ref for the About section

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  }
  return (
    <div>
     <NavBar/>
     <Hero onLearnMoreClick={() => scrollToSection(aboutRef)}/> 
      <div ref={aboutRef}><About/></div> 
     <ReasonsDisplay/>
     <MentorReasonsSection/>
     <MailList/> 
     
     {/* <Footer/> */}
    </div>
  )
}
