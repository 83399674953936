import React, { useState, useEffect } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const NavBar = () => {
  const [nav, setNav] = useState(false);
  const { currentUser, logout } = useAuth(); // useAuth() to access the current user and logout function
  const navigate = useNavigate();

  const handleNav = () => {
    setNav(!nav);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login'); // Redirect to login page after logout
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  useEffect(() => {
    if (currentUser) {
      // Logic to handle when the user is logged in
    }
  }, [currentUser]);

  return (
    <nav className="bg-blue-800 p-4 sticky top-0 z-50">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" className="flex items-center text-white text-2xl font-semibold">
          <img src="https://i.ibb.co/RywmkKn/Whats-App-Image-2024-03-19-at-19-53-06.png" alt="MentoMenti Logo" className="mr-2 h-8" />
          MentoMenti
        </Link>
        <div className="hidden md:flex space-x-6 items-center">
          <Link to="/" className="text-white hover:text-blue-200">Home</Link>
          <Link to="/aboutmentors" className="text-white hover:text-blue-200">Mentor</Link>
          <Link to="/aboutmentees" className="text-white hover:text-blue-200">Mentee</Link>
          <Link to="/about" className="text-white hover:text-blue-200">About</Link>
          <Link to="/contact" className="text-white hover:text-blue-200">Contact</Link>
          {currentUser ? (
            <button onClick={handleLogout} className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
              Logout
            </button>
          ) : (
            <Link to="/login" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              Login
            </Link>
          )}
        </div>
        <div className="md:hidden" onClick={handleNav}>
          {nav ? <AiOutlineClose size={20} className="text-white" /> : <AiOutlineMenu size={20} className="text-white" />}
        </div>
      </div>

      <div
        className={
          nav
            ? 'fixed left-0 top-16 w-[60%] h-full bg-gradient-to-t from-blue-900 via-blue-800 to-blue-700 max-w-[400px] ease-in-out ease-out duration-500'
            : 'fixed left-[-100%]'
        }
      >
        <ul className="p-2 uppercase">
          <li className="p-4 border-b border-blue-600">
            <Link to="/" onClick={() => setNav(false)} className="text-[#baffe9] hover:text-white">Home</Link>
          </li>
          <li className="p-4 border-b border-blue-600">
            <Link to="/aboutmentors" onClick={() => setNav(false)} className="text-[#baffe9] hover:text-white">Mentor</Link>
          </li>
          <li className="p-4 border-b border-blue-600">
            <Link to="/aboutmentees" onClick={() => setNav(false)} className="text-[#baffe9] hover:text-white">Mentee</Link>
          </li>
          <li className="p-4 border-b border-blue-600">
            <Link to="/about" onClick={() => setNav(false)} className="text-[#baffe9] hover:text-white">About</Link>
          </li>
          <li className="p-4">
            <Link to="/contact" onClick={() => setNav(false)} className="text-[#baffe9] hover:text-white">Contact</Link>
          </li>
          {currentUser && (
            <li className="p-4">
              <button onClick={handleLogout} className="text-[#baffe9] hover:text-white font-bold py-2 px-4 rounded">Logout</button>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
