import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, getDocs, doc, setDoc, deleteDoc } from 'firebase/firestore';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

const AdminApprovalPage = () => {
    const [applications, setApplications] = useState([]);
    const [loading, setLoading] = useState(true);
    const auth = getAuth();

    useEffect(() => {
        const fetchApplications = async () => {
            const querySnapshot = await getDocs(collection(db, "mentorApplications"));
            const apps = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setApplications(apps);
            setLoading(false);
        };

        fetchApplications();
    }, []);

    const handleApprove = async (application) => {
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, application.email, application.password);
            const user = userCredential.user;

            await setDoc(doc(db, "mentors", user.uid), {
                ...application,
                uid: user.uid,
                hoursMentored: 0,
                sessions: 0,
                mentees: 0,
                approved: true
            });

            await deleteDoc(doc(db, "mentorApplications", application.id));

            setApplications(applications.filter(app => app.id !== application.id));

            alert("Mentor approved successfully!");
        } catch (error) {
            console.error('Error approving application:', error);
            alert('Error approving application.');
        }
    };

    const handleEdit = (id, field, value) => {
        const updatedApplications = applications.map(app => {
            if (app.id === id) {
                return { ...app, [field]: value };
            }
            return app;
        });
        setApplications(updatedApplications);
    };

    return (
        <div className="bg-gradient-to-b from-blue-500 to-indigo-500 min-h-screen">
            <div className="container mx-auto p-4">
                <h1 className="text-3xl font-bold text-white mb-4 text-center">Mentor Applications</h1>
                {loading ? (
                    <p className="text-white text-center">Loading applications...</p>
                ) : (
                    applications.map((application) => (
                        <div key={application.id} className="bg-white p-4 rounded-lg shadow mb-4">
                            <div className="flex">
                                <img src={application.profilePicture} alt={application.name} className="w-32 h-32 rounded-full mr-4" />
                                <div className="flex-grow">
                                    <div className="mb-2">
                                        <label className="font-bold">Name:</label>
                                        <input
                                            type="text"
                                            value={application.name}
                                            onChange={(e) => handleEdit(application.id, 'name', e.target.value)}
                                            className="ml-2 p-1 border rounded w-full"
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label className="font-bold">Email:</label>
                                        <input
                                            type="email"
                                            value={application.email}
                                            onChange={(e) => handleEdit(application.id, 'email', e.target.value)}
                                            className="ml-2 p-1 border rounded w-full"
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label className="font-bold">Bio:</label>
                                        <textarea
                                            value={application.bio}
                                            onChange={(e) => handleEdit(application.id, 'bio', e.target.value)}
                                            className="ml-2 p-1 border rounded w-full"
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label className="font-bold">Skills:</label>
                                        <input
                                            type="text"
                                            value={application.skills.join(', ')}
                                            onChange={(e) => handleEdit(application.id, 'skills', e.target.value)}
                                            className="ml-2 p-1 border rounded w-full"
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label className="font-bold">Credentials:</label>
                                        <input
                                            type="text"
                                            value={application.credentials}
                                            onChange={(e) => handleEdit(application.id, 'credentials', e.target.value)}
                                            className="ml-2 p-1 border rounded w-full"
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label className="font-bold">Grade Level:</label>
                                        <input
                                            type="text"
                                            value={application.gradeLevel}
                                            className="ml-2 p-1 border rounded w-full"
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label className="font-bold">Reason for why a good mentor:</label>
                                        <textarea
                                            value={application.goodMentorReason}
                                            className="ml-2 p-1 border rounded w-full"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <button
                                    onClick={() => handleApprove(application)}
                                    className="bg-blue-600 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded ml-4"
                                >
                                    Approve
                                </button>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default AdminApprovalPage;
