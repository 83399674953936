import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import NavBar from './Components/NavBar';

const MenteeInfoPage = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      title: "Welcome to Our WebApp",
      content: "An amazing platform for mentors and mentees to connect and grow.",
      image: "https://via.placeholder.com/400"
    },
    {
      title: "Want to learn a new skill?",
      content: "Share your knowledge and help others achieve their dreams.",
      image: "https://via.placeholder.com/400"
    },
    {
      title: "Find a Mentor",
      content: "Connect with industry experts and gain insights into your desired field.",
      image: "https://via.placeholder.com/400"
    },
    {
      title: "Join Now",
      content: "Become a part of our community and take the next step in your journey.",
      image: "https://via.placeholder.com/400"
    }
  ];

  return (
    <div>
      <NavBar/>
    <div className="min-h-screen bg-gradient-to-b from-blue-400 to-blue-600 flex flex-col">
      <div className="flex-1 flex justify-center items-center p-6">
        <div className="max-w-3xl w-full bg-white p-6 rounded-lg shadow-lg">
          <img src={slides[currentSlide].image} alt={slides[currentSlide].title} className="w-full h-60 object-cover rounded-md" />
          <h2 className="text-3xl mt-6 mb-4 font-semibold">{slides[currentSlide].title}</h2>
          <p>{slides[currentSlide].content}</p>
        </div>
      </div>

      {/* Navigation Footer */}
      <div className="bg-gray-800 bg-opacity-80 p-6 shadow-inner">
        <div className="container mx-auto flex justify-between items-center">
          {
            currentSlide !== slides.length - 1 ? (
              <>
                <button 
                  className={`px-6 py-2 font-bold rounded-full ${currentSlide === 0 ? 'opacity-50 cursor-not-allowed' : 'bg-white text-blue-600 hover:bg-gray-200'}`}
                  disabled={currentSlide === 0}
                  onClick={() => setCurrentSlide(prev => prev - 1)}
                >
                  Previous
                </button>
                <button 
                  className={`px-6 py-2 bg-white font-bold rounded-full ${currentSlide === slides.length - 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-200'}`}
                  disabled={currentSlide === slides.length - 1}
                  onClick={() => setCurrentSlide(prev => prev + 1)}
                >
                  Next
                </button>
              </>
            ) : (
              <>
                <button 
                  className="px-6 py-2 font-bold rounded-full bg-white text-blue-600 hover:bg-gray-200"
                  onClick={() => setCurrentSlide(prev => prev - 1)}
                >
                  Previous
                </button>
                <Link to="/signup" className="px-6 py-2 bg-white font-bold rounded-full hover:bg-gray-200">
                  Sign Up
                </Link>
              </>
            )
          }
        </div>
      </div>
    </div>
    </div>
  );
};

export default MenteeInfoPage;
