import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { db } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import MenteeNavBar from './Components/MenteeNavbar'; 
import ProfilePictureHelpModal from './PfpModal';

const EditMenteeProfile = () => {
  const { currentUser } = useAuth();
  const [menteeData, setMenteeData] = useState({
    name: '',
    bio: '',
    school: '',
    grade: '',
    interests: '',
    profilePicture: 'https://via.placeholder.com/150',
  });
  const [showHelpModal, setShowHelpModal] = useState(false);

  useEffect(() => {
    const fetchMenteeData = async () => {
      try {
        const docRef = doc(db, "mentees", currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setMenteeData({
            ...data,
            interests: data.interests ? data.interests.join(', ') : ''
          });
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching mentee data:", error);
      }
    };

    fetchMenteeData();
  }, [currentUser.uid]);

  const handleChange = (e) => {
    setMenteeData({ ...menteeData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedInterests = menteeData.interests.split(',').map(interest => interest.trim()).filter(interest => interest);
    const updatedData = {
      ...menteeData,
      interests: updatedInterests,
    };
    const menteeRef = doc(db, "mentees", currentUser.uid);

    try {
      await updateDoc(menteeRef, updatedData);
      alert("Profile updated successfully");
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-400 via-pink-500 to-red-500">
      <MenteeNavBar />
      <div className="container mx-auto p-8">
        <h1 className="text-4xl font-bold mb-8 text-center text-white">Edit Your Profile</h1>
        <div className="max-w-4xl mx-auto bg-white p-8 rounded-xl shadow-xl">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="text-center">
              <label htmlFor="profilePicture" className="block text-gray-700 font-bold mb-2">Profile Picture URL:</label>
              <div className="flex">
                <input
                  type="text"
                  id="profilePicture"
                  name="profilePicture"
                  value={menteeData.profilePicture}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded text-gray-700"
                  placeholder="https://example.com/your-photo.jpg"
                />
                <button
                  type="button"
                  onClick={() => setShowHelpModal(true)}
                  className="ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Help
                </button>
              </div>
              <img src={menteeData.profilePicture || 'https://via.placeholder.com/150'} alt="Profile" className="w-32 h-32 rounded-full mx-auto mt-4" />
            </div>
            <div>
              <label htmlFor="name" className="block text-gray-700 font-bold mb-2">Name:</label>
              <input type="text" id="name" name="name" value={menteeData.name} onChange={handleChange} className="w-full px-3 py-2 border rounded text-gray-700" required />
            </div>
            <div>
              <label htmlFor="bio" className="block text-gray-700 font-bold mb-2">Bio:</label>
              <textarea id="bio" name="bio" value={menteeData.bio} onChange={handleChange} className="w-full px-3 py-2 border rounded text-gray-700"></textarea>
            </div>
            <div>
              <label htmlFor="school" className="block text-gray-700 font-bold mb-2">School:</label>
              <input type="text" id="school" name="school" value={menteeData.school} onChange={handleChange} className="w-full px-3 py-2 border rounded text-gray-700" />
            </div>
            <div>
              <label htmlFor="grade" className="block text-gray-700 font-bold mb-2">Grade:</label>
              <input type="text" id="grade" name="grade" value={menteeData.grade} onChange={handleChange} className="w-full px-3 py-2 border rounded text-gray-700" />
            </div>
            <div>
              <label htmlFor="interests" className="block text-gray-700 font-bold mb-2">Interests (separated by commas):</label>
              <input type="text" id="interests" name="interests" value={menteeData.interests} onChange={handleChange} className="w-full px-3 py-2 border rounded text-gray-700" />
            </div>
            <div className="flex justify-center mt-6">
              <button type="submit" className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-lg transition duration-200 ease-in-out transform hover:-translate-y-1 shadow">
                Update Profile
              </button>
            </div>
          </form>
        </div>
      </div>
      <ProfilePictureHelpModal showHelpModal={showHelpModal} setShowHelpModal={setShowHelpModal} />
    </div>
  );
};

export default EditMenteeProfile;
