import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import {getFirestore } from "@firebase/firestore"
import 'firebase/compat/storage';


const app = firebase.initializeApp ({
  apiKey: "AIzaSyANztJU4iApdceXu6JawHXO3KxGq-E5sCE",
  authDomain: "mentomentee-71812.firebaseapp.com",
  projectId: "mentomentee-71812",
  storageBucket: "mentomentee-71812.appspot.com",
  messagingSenderId: "67441167793",
  appId: "1:67441167793:web:e1fec20b35b43f0bf6c190",
  measurementId: "G-YZSGXDY495"
})

export const auth = app.auth()

export const db = getFirestore(app)
export const storage = app.storage(); 
export default app