import React, {useContext, useState, useEffect} from 'react'
import { auth, db } from '../firebase'
import { sendPasswordResetEmail } from 'firebase/auth';
import { doc,getDoc } from 'firebase/firestore';

const AuthContext = React.createContext()

export function useAuth() {
    
    return useContext(AuthContext)
}

export default function AuthProvider({ children}) {
    const [userRole, setUserRole] = useState(null); // To store user role
    const [currentUser, setCurrentUser] = useState()
    const[loading, setLoading] = useState(true)

    function signup(email, password) {
        return auth.createUserWithEmailAndPassword(email, password)
    }

    const resetPassword = (email) => {
        return sendPasswordResetEmail(auth, email);
      };

    function login(email, password) {
        return auth.signInWithEmailAndPassword(email, password)
        
    }

    function logout () {
        return auth.signOut()
    }

    // Function to determine user role
  const getUserRole = async (userId) => {
    const mentorRef = doc(db, 'mentors', userId);
    const mentorSnap = await getDoc(mentorRef);

    if (mentorSnap.exists()) {
      return 'mentor';
    } else {
      const menteeRef = doc(db, 'mentees', userId);
      const menteeSnap = await getDoc(menteeRef);
      if (menteeSnap.exists()) {
        return 'mentee';
      }
    }
    return null; 
  };


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
      if (user) {
        getUserRole(user.uid).then(role => {
          setUserRole(role); // Set the user role in state
          setLoading(false);
        });
      } else {
        setLoading(false); // User is logged out, no role to fetch
      }
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    userRole,
    login,
    signup,
    logout,
    resetPassword
  };
  
  
    return (
    <div>
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
      
    </div>
  )
}
