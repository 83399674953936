import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const PrivateRoutes = ({ allowedRoles }) => {
  const { currentUser, userRole } = useAuth();

  // Ensure allowedRoles is an array and userRole is not undefined
  if (!allowedRoles || !Array.isArray(allowedRoles)) {
    console.error('allowedRoles must be an array');
    return null; // or handle this case as needed
  }

  // Wait until userRole is determined (i.e., not undefined)
  if (userRole === undefined) {
    return null; // or <LoadingIndicator /> if you prefer to show a loading state
  }

  return currentUser && allowedRoles.includes(userRole) ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoutes;
