import React, { useRef, useState } from 'react';
import { useAuth } from '../../../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { db } from '../../../firebase';
import { doc, setDoc } from 'firebase/firestore';

export default function SignUp() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const gradeLevelRef = useRef();
  const schoolRef = useRef();
  const interestsRef = useRef();
  const referralRef = useRef();
  const { signup } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [newName, setNewName] = useState('');
  const navigate = useNavigate();

  const createMentee = async (uid) => {
    const interestsArray = interestsRef.current.value.split(',').map(interest => interest.trim());
    const menteeData = {
      name: newName,
      bio: 'Hello! I am a mentee.',
      school: schoolRef.current.value,
      grade: gradeLevelRef.current.value,
      credentials: 'None',
      interests: interestsArray,
      email: emailRef.current.value,
      referral: referralRef.current.value || 'None'
    };

    try {
      await setDoc(doc(db, "mentees", uid), menteeData);
      console.log('Mentee document successfully written!');
      navigate('/MenteeDashboard');
    } catch (error) {
      console.error('Error writing mentee document: ', error);
      setError('Failed to create an account');
    }
    setLoading(false);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError('Passwords do not match');
    }

    setError('');
    setLoading(true);
    try {
      const userCredential = await signup(emailRef.current.value, passwordRef.current.value);
      const user = userCredential.user;
      console.log("User's UID:", user.uid);
      await createMentee(user.uid);
    } catch (error) {
      console.error(error);
      setError('Failed to create an account');
    }
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-blue-400 to-blue-600">
      <div className="w-full max-w-md bg-white p-8 rounded shadow-lg">
        <h2 className="text-3xl font-semibold mb-4 text-center">Mentee Sign Up</h2>
        {error && <div className="bg-red-100 text-red-500 mb-4 p-3 rounded">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2">Full Name</label>
            <input
              type="text"
              id="name"
              onChange={(event) => setNewName(event.target.value)}
              className="border border-gray-300 rounded px-4 py-2 w-full focus:outline-none focus:ring focus:border-blue-400"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700 font-bold mb-2">Email</label>
            <input
              type="email"
              id="email"
              ref={emailRef}
              className="border border-gray-300 rounded px-4 py-2 w-full focus:outline-none focus:ring focus:border-blue-400"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="block text-gray-700 font-bold mb-2">Password</label>
            <input
              type="password"
              id="password"
              ref={passwordRef}
              className="border border-gray-300 rounded px-4 py-2 w-full focus:outline-none focus:ring focus:border-blue-400"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="confirm-password" className="block text-gray-700 font-bold mb-2">Confirm Password</label>
            <input
              type="password"
              id="confirm-password"
              ref={passwordConfirmRef}
              className="border border-gray-300 rounded px-4 py-2 w-full focus:outline-none focus:ring focus:border-blue-400"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="gradeLevel" className="block text-gray-700 font-bold mb-2">Grade Level</label>
            <select
              id="gradeLevel"
              ref={gradeLevelRef}
              className="border border-gray-300 rounded px-4 py-2 w-full focus:outline-none focus:ring focus:border-blue-400"
              required
            >
              <option value="">Select your grade level</option>
              <option value="9">Grade 9</option>
              <option value="10">Grade 10</option>
              <option value="11">Grade 11</option>
              <option value="12">Grade 12</option>
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="school" className="block text-gray-700 font-bold mb-2">School</label>
            <select
              id="school"
              ref={schoolRef}
              className="border border-gray-300 rounded px-4 py-2 w-full focus:outline-none focus:ring focus:border-blue-400"
              required
            >
              <option value="">Select your school</option>
              <option value="ISKL">ISKL</option>
              <option value="SAS">SAS</option>
              <option value="ISB">ISB</option>
              <option value="TAS">TAS</option>
              <option value="JIS">JIS</option>
              <option value="ISM">ISM</option>
              <option value="GIS">GIS</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="interests" className="block text-gray-700 font-bold mb-2">Interests (Separated by Commas)</label>
            <input
              type="text"
              id="interests"
              ref={interestsRef}
              className="border border-gray-300 rounded px-4 py-2 w-full focus:outline-none focus:ring focus:border-blue-400"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="referral" className="block text-gray-700 font-bold mb-2">Referral (Leave blank otherwise)</label>
            <input
              type="text"
              id="referral"
              ref={referralRef}
              className="border border-gray-300 rounded px-4 py-2 w-full focus:outline-none focus:ring focus:border-blue-400"
              placeholder="Referral code or name"
            />
          </div>
          <button
            type="submit"
            disabled={loading}
            className="bg-blue-500 text-white rounded px-4 py-2 w-full hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-400"
          >
            Sign Up
          </button>
        </form>
        <div className="text-center mt-4">
          Already have an account? <Link to="/login" className="text-blue-500 hover:underline">Log In</Link>
        </div>
      </div>
    </div>
  );
}
