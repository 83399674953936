import React, { useState } from 'react';
import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';
import NavBar from './Components/NavBar';  // Assuming NavBar is the regular navbar component

const ContactPublicPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "publicContact"), {  // Change to 'publicContact'
        ...formData,
        timestamp: new Date()
      });
      alert('Your message has been sent successfully.');
      setFormData({ name: '', email: '', message: '' }); // Reset form fields after submission
    } catch (error) {
      console.error("Error submitting contact form: ", error);
      alert('Error sending message, please try again.');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-400 to-blue-600">
      <NavBar />
      <div className="container mx-auto p-8">
        <h1 className="text-4xl font-bold mb-4 text-white text-center">Contact Us</h1>
        <p className="text-white text-lg text-center mb-2">
          Have any concerns, suggestions, or questions? Feel free to reach out to us with this form!
        </p>
        <p className="text-white text-lg text-center mb-5">
          Alternatively, email: <a href="mailto:mentomenti.team@gmail.com" className="font-bold text-lg underline">mentomenti.team@gmail.com</a>
        </p>
        <div className="max-w-3xl mx-auto bg-white p-8 rounded-xl shadow-xl">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="mb-4">
              <label htmlFor="name" className="block text-gray-700 font-bold mb-2">Name</label>
              <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} className="w-full px-3 py-2 border rounded text-gray-700" required />
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-700 font-bold mb-2">Email</label>
              <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} className="w-full px-3 py-2 border rounded text-gray-700" required />
            </div>
            <div className="mb-6">
              <label htmlFor="message" className="block text-gray-700 font-bold mb-2">Message</label>
              <textarea id="message" name="message" value={formData.message} onChange={handleChange} className="w-full px-3 py-2 border rounded text-gray-700" rows="4" required></textarea>
            </div>
            <div className="flex justify-center">
              <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg transition duration-200 ease-in-out transform hover:-translate-y-1 shadow">
                Send Message
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactPublicPage;
