import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const About = () => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);

  return (
    <div className="w-full bg-gradient-to-b from-blue-400 to-blue-600 py-16 px-4">
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-2 items-center">
        <div>
          <img
            className="w-[400px] mx-auto md:pr-5 my-4 w-360 h-360 rounded-full"
            src={'https://i.ibb.co/9NTJV0r/DALL-E-2024-02-11-17-31-44-Create-an-image-with-a-blue-and-white-theme-that-symbolizes-mentorship-fe.webp'}
            alt="helping"
          />
          <p className="text-gray-200 text-center text-xs">Image generated using DALL-E</p>
        </div>
        
        <div className="text-white">
          <p className="text-white font-bold">What is MentoMenti?</p>
          <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
            About Us
          </h1>
          <p className="text-gray-200 text-lg">
            MentoMenti is the world's first peer-to-peer mentoring platform. We connect people seeking to expand their knowledge in a subject, like programming, with experienced mentors. Often, many of us, while trying to learn something new face many roadblocks that make it harder and harder to pursue your journey to learn that thing. We can spend hours googling - repeatedly trying and failing - wrecking motivation and unproductively spending our precious time. But having an experienced mentor, like an elder sibling  whos been through a similar journey as you, can guide you to success and can be invaluable to learning.
          </p>
          <button
            onClick={toggleModal}
            className="text-white bg-blue-600 hover:bg-blue-700 w-[200px] rounded-md font-bold my-6 mx-auto md:mx-0 py-3 transition duration-300"
          >
            Get Started
          </button>
        </div>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg mx-2">
            <h3 className="text-xl font-semibold text-blue-600 mb-4 text-center">Join MentoMenti</h3>
            <p className="text-gray-800 mb-6 text-center">
              Are you looking to grow as a learner or to guide others in their learning journey? Decide your path today!
            </p>
            <div className="text-center space-y-4">
              <Link to="/aboutmentors" className="block bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700">
                Be a Mentor
              </Link>
              <Link to="/aboutmentees" className="block bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700">
                Be a Mentee
              </Link>
            </div>
            <button
              onClick={toggleModal}
              className="mt-4 w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default About;
