import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs } from "firebase/firestore";
import MentorNavBar from './Components/MentorNavBar';
import { Link } from 'react-router-dom';

const MentorConnectPage = () => {
  const [mentorsData, setMentorsData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchMentors = async () => {
      try {
        const mentorsCollection = collection(db, "mentors");
        const mentorSnapshot = await getDocs(mentorsCollection);
        const mentorList = mentorSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        setMentorsData(mentorList);
      } catch (error) {
        console.error("Error fetching mentors: ", error);
      }
    };

    fetchMentors();
  }, []);

  const truncateText = (text, limit) => {
    if (text.length > limit) {
      return `${text.substring(0, limit)}...`;
    }
    return text;
  };

  const filteredMentors = mentorsData.filter((mentor) =>
    mentor.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    mentor.skills.some(skill => skill.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-blue-50 to-blue-200">
      <MentorNavBar/>

      <div className="text-center p-6 bg-blue-100 border-b border-blue-300">
        <h2 className="text-2xl font-bold text-blue-900 mb-2">Connect with Fellow Mentors</h2>
        <p className="text-md text-blue-800">
          Network and collaborate with other mentors to share insights and experiences.
        </p>
      </div>

      <header className="w-full bg-blue-600 shadow-md p-4 text-white text-center">
        <div className="max-w-7xl mx-auto flex items-center justify-between">
          <input
            type="text"
            placeholder="Search mentors by name or skills..."
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-800"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button className="ml-4 bg-blue-800 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded">
            Search
          </button>
        </div>
      </header>

      <main className="flex-grow p-6 flex items-center justify-center">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-6xl">
          {filteredMentors.length > 0 ? (
            filteredMentors.map((mentor) => (
              <Link key={mentor.id} to={`/mentorConnect/${mentor.id}`} className="bg-white shadow-lg rounded-xl p-6 hover:shadow-xl transition-shadow duration-300 ease-in-out">
                <div className="flex items-center mb-4">
                  <img
                    src={mentor.profilePicture}
                    alt={mentor.name}
                    className="w-20 h-20 rounded-full mr-4 object-cover"
                  />
                  <div>
                    <h3 className="text-xl font-semibold">{mentor.name}</h3>
                    <p className="text-sm text-gray-500">{truncateText(mentor.credentials, 100)}</p>
                  </div>
                </div>
                
                <p className="text-gray-600 mb-3">Skills: <span className="text-blue-600">{truncateText(mentor.skills.join(', '), 50)}</span></p>
                <p className="text-gray-700 mb-3">{truncateText(mentor.bio, 200)}</p>

                <div className="pt-4 border-t mt-4">
                  <p className="text-gray-600">Sessions: <span className="font-semibold">{mentor.sessions}</span></p>
                  <p className="text-gray-600">Mentees: <span className="font-semibold">{mentor.mentees}</span></p>
                </div>
              </Link>
            ))
          ) : (
            <p className="text-gray-600">No mentors found matching the search criteria.</p>
          )}
        </div>
      </main>

      <footer className="w-full bg-blue-600 shadow-inner">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-center items-center">
          <p className="text-base text-white">&copy; {new Date().getFullYear()} MentorMenti. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default MentorConnectPage;
