import React, {useRef} from 'react';
import Typed from 'react-typed';
import { Link } from 'react-router-dom';



const Hero = ({ onLearnMoreClick }) => {
  const aboutRef = useRef(null); 


  

  return (
    <div className="bg-gradient-to-b from-blue-400 to-blue-600 h-screen text-white">
      <div className="max-w-4xl mx-auto py-32 text-center">
        <p className="text-2xl md:text-3xl text-blue-200 font-bold mb-2">FROM PEER TO PEER</p>
        <h1 className="text-5xl md:text-6xl font-bold md:pt-4">Grow with Knowledge</h1>
        <div className="flex justify-center items-center mt-4">
          <p className="text-3xl md:text-4xl font-bold">Be a mentor or mentee in</p>
          <Typed
            className="text-3xl md:text-4xl font-bold ml-2"
            strings={['Creative Writing', 'AP Calculus', 'Programming', 'IB Chemistry', 'Public Speaking', 'Chess']}
            typeSpeed={80}
            backSpeed={80}
            loop
          />
        </div>
        <p className="text-xl md:text-2xl font-bold text-gray-300 mt-4">
          A student based peer-to-peer mentoring platform.
        </p>
        <p className="text-xl md:text-2xl font-bold text-gray-300 ">
        Connecting mentors with mentees.
        </p>

        <div className="mt-8">

        <button
            onClick={onLearnMoreClick}
            
            className="bg-blue-100 w-48 md:w-56 rounded-full font-bold py-3 text-black mb-4"
          >
            Learn More
          </button>
          
          <p className="text-xl md:text-2xl font-bold text-blue-200"> and Become a...</p>
          <div className="flex justify-center items-center my-6">
          <Link to="/mentorsignup">

              <button className="bg-blue-200 w-48 md:w-56 rounded-md font-bold mx-2 py-3 text-black">
                Mentor
              </button>
            </Link>
            <Link to="/signup">
              <button className="bg-blue-200 w-48 md:w-56 rounded-md font-bold mx-2 py-3 text-black">
                Mentee
              </button>
            </Link>
          </div>
          <div className="text-white-200 text-small font-bold mt-4">
  <p>Already a user? <Link to="/login" className="underline">Login</Link></p>

</div>
          
        </div>
      </div>
    </div>
  );
};

export default Hero;
