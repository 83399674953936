import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs } from "firebase/firestore";
import MentorNavBar from './Components/MentorNavBar'; 
import { Link } from 'react-router-dom';

const FindMenteesPage = () => {
  const [menteesData, setMenteesData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchMentees = async () => {
      try {
        const menteesCollection = collection(db, "mentees");
        const menteeSnapshot = await getDocs(menteesCollection);
        const menteeList = menteeSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        setMenteesData(menteeList);
      } catch (error) {
        console.error("Error fetching mentees: ", error);
      }
    };

    fetchMentees();
  }, []);

  const filteredMentees = menteesData.filter((mentee) =>
    mentee.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (Array.isArray(mentee.skills) && mentee.skills.some(skill => skill.toLowerCase().includes(searchTerm.toLowerCase()))) ||
    (Array.isArray(mentee.interests) && mentee.interests.some(interest => interest.toLowerCase().includes(searchTerm.toLowerCase())))
  );

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-blue-50 to-blue-200">
      <MentorNavBar/>

      <div className="text-center p-6 bg-blue-100 border-b border-blue-300">
        <h2 className="text-2xl font-bold text-blue-900 mb-2">Find Potential Mentees</h2>
        <p className="text-md text-blue-800">
          Explore mentees who share similar interests and reach out to them to offer your guidance and support.
        </p>
      </div>

      <header className="w-full bg-blue-600 shadow-md p-4">
        <div className="max-w-7xl mx-auto flex items-center justify-between">
          <input
            type="text"
            placeholder="Search mentees by name, skills, or interests..."
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-800"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button className="ml-4 bg-blue-800 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded">
            Search
          </button>
        </div>
      </header>

      <main className="flex-grow p-6 flex items-center justify-center">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-6xl">
          {filteredMentees.length > 0 ? (
            filteredMentees.map((mentee) => (
              <Link key={mentee.id} to={`/mentee/${mentee.id}`} className="bg-white shadow-lg rounded-xl p-6 hover:shadow-xl transition-shadow duration-300 ease-in-out">
                <div className="mb-4">
                  <h3 className="text-xl font-semibold">{mentee.name}</h3>
                  <p className="text-sm text-gray-500">{mentee.grade || 'Grade not specified'}</p>
                </div>
                <p className="text-gray-600 mb-3">Interests: <span className="text-blue-600">{Array.isArray(mentee.interests) ? mentee.interests.join(', ') : mentee.interests || 'Not specified'}</span></p>
                <p className="text-gray-700 mb-3">{mentee.bio || 'No bio available'}</p>
              </Link>
            ))
          ) : (
            <p className="text-gray-600">No mentees found matching the search criteria.</p>
          )}
        </div>
      </main>

      <footer className="w-full bg-blue-600 shadow-inner">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-center items-center">
          <p className="text-base text-white">&copy; {new Date().getFullYear()} MentorMenti. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default FindMenteesPage;
