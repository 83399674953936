import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { getAuth, getUser } from 'firebase/auth'; 
import MentorNavBar from './Components/MentorNavBar';

const MenteeProfile = () => {
  const { id } = useParams();
  const [menteeData, setMenteeData] = useState(null);
  const [menteeEmail, setMenteeEmail] = useState('');

  useEffect(() => {
    const fetchMenteeData = async () => {
      try {
        const menteeRef = doc(db, "mentees", id);
        const menteeSnap = await getDoc(menteeRef);
        const auth = getAuth();

        if (menteeSnap.exists()) {
          setMenteeData(menteeSnap.data());

          const user = await auth.getUser(id); 
          setMenteeEmail(user.email);
        } else {
          console.log("Mentee not found");
        }
      } catch (error) {
        console.error("Error fetching mentee data:", error);
      }
    };

    fetchMenteeData();
  }, [id]);

  if (!menteeData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-gradient-to-br from-cyan-500 to-blue-700 min-h-screen">
      <MentorNavBar />
      <div className="max-w-4xl mx-auto p-6 text-white">
        <div className="bg-white bg-opacity-10 backdrop-blur-lg rounded-lg shadow-xl p-6 space-y-4">
          <h1 className="text-3xl font-bold">{menteeData.name}</h1>
          <p className="text-xl">Grade: {menteeData.grade}</p>
          <p className="text-xl">Interests: {menteeData.interests ? menteeData.interests.join(', ') : 'Not specified'}</p>
          <p className="text-xl mt-4">{menteeData.bio}</p>
          <div className="mt-6">
            <a href={`mailto:${menteeData.email}?subject=Mentorship Inquiry`}
               className="bg-blue-600 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded-full transition ease-in duration-200">
              Email {menteeData.name}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
 
};

export default MenteeProfile;
