import React from 'react';
import NavBar from './Components/NavBar';

const AboutPage = () => {

  const teamMembers = [
    {
      name: 'Dev Chaliha',
      role: 'Founder and Lead Developer',
      bio: 'HS Student and Full-stack developer passionate about building scalable web applications. ',
      imageUrl: 'https://i.ibb.co/nmssj0t/Screenshot-2024-03-22-at-5-44-02-PM.png', 
    },
    {
      name: 'Pulkit Chaudhari',
      role: 'Head of Marketing and Outreach',
      bio: 'HS Student interested in finance and business. Founder of think-dollars.com',
      imageUrl: 'https://i.ibb.co/QvKXWKK/Screenshot-2024-04-05-at-11-13-40-AM.png',
      
    },
    
    {
      name: 'Hyren Gan',
      role: 'Operations Team',
      bio: 'HS Student interested in politics, public-speaking and aviation.  ',
      imageUrl: 'https://i.ibb.co/TRQq6pf/Screenshot-2024-03-22-at-5-42-03-PM.png',
      
    },
    {
      name: 'Chanjin (Jordan) Ko',
      role: 'Operations Team',
      bio: 'HS Student with a keen interest in chemistry and public speaking',
      imageUrl: 'https://i.ibb.co/xXRxNJX/Screenshot-2024-03-22-at-5-38-38-PM.png',
      
    },
    {
      name: 'Thomas Quentin Tsao',
      role: 'Operations Team',
      bio: 'HS Student interested public speaking, politics, and basketball',
      imageUrl: 'https://i.ibb.co/rZ8r4HL/Screenshot-2024-04-07-at-2-54-33-AM.png',
      
    },
    
    // ... Add more team members as needed
  ];

  return (
    <div>
      <NavBar />
      <div className="bg-gradient-to-b from-blue-400 to-blue-600 min-h-screen flex flex-col justify-center">
        <div className="max-w-6xl mx-auto text-center p-8">
          <h1 className="text-5xl font-bold text-white mb-8">About the Project</h1>
          <p className="text-xl text-gray-200 mb-8">
            Often, many of us, while trying to learn something new face many roadblocks that make it harder and harder to pursue your journey to learn that thing. We can spend hours googling - repeatedly trying and failing - wrecking motivation and unproductively spending our precious time. But having an experienced mentor who can guide you to success is often time invaluable to learning.
          </p>
          <br/>
          <p className="text-xl text-gray-200 mb-8">
          Founders note: I realized the immense value of one to one mentoring for both mentees and mentors. I recognized a lack of accessible, personalized mentorship opportunities for students in diverse academic fields. Inspired by personal academic experiences -- trying to learn to code -- I was driven to create a solution that empowers students through mentorship. This brought us MentoMenti
          </p>
          <br/>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h2 className="text-3xl font-semibold mb-4">Our Mission</h2>
              <p className="text-gray-800">
                Our mission is to make a positive impact on the world through technology and creativity.
                We strive to create unique mentorship oppurtunities, connect highschoolers of diverse interests.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h2 className="text-3xl font-semibold mb-4">Our Team</h2>
              <p className="text-gray-800">
                We are a dedicated team of student developers, marketers and visionaries who work together to
                turn ideas into reality. Meet the people behind MentoMenti.
              </p>
            </div>
          </div>

          {/* Team Member Profiles */}
          <div className="mt-12">
            <h2 className="text-4xl text-white font-bold mb-6">Meet Our Team</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {teamMembers.map((member, index) => (
                <div key={index} className="bg-white p-6 rounded-lg shadow-lg">
                  <img src={member.imageUrl} alt={member.name} className="w-24 h-24 rounded-full object-cover mx-auto" />
                  <h3 className="text-2xl font-semibold mt-4">{member.name}</h3>
                  <p className="text-blue-600">{member.role}</p>
                  <p className="text-gray-600 mt-2">{member.bio}</p>
                </div>
              ))}
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default AboutPage;
