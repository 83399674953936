import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { getFirestore, setDoc, doc } from "firebase/firestore";

const AdminPage = () => {
    const [mentorData, setMentorData] = useState({
        email: '',
        password: '',
        name: '',
        bio: '',
        credentials: '',
        grade: '',
        skills: '',
        profilePicture: '',
        experienceLevel: ''
    });

    const auth = getAuth();
    const firestore = getFirestore();

    const handleChange = (e) => {
        setMentorData({ ...mentorData, [e.target.name]: e.target.value });
    };

    const createMentorAccount = async () => {
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, mentorData.email, mentorData.password);
            const user = userCredential.user;

            await setDoc(doc(firestore, "mentors", user.uid), {
                email: mentorData.email,
                name: mentorData.name,
                bio: mentorData.bio,
                credentials: mentorData.credentials,
                grade: mentorData.grade,
                skills: mentorData.skills.split(',').map(skill => skill.trim()),
                profilePicture: mentorData.profilePicture,
                experienceLevel: mentorData.experienceLevel,
                hoursMentored: 0,
                mentees: 0,
                sessions: 0,
            });

            alert('Mentor account created successfully');
            setMentorData({ email: '', password: '', name: '', bio: '', credentials: '', grade: '', skills: '', profilePicture: '', experienceLevel: '' });
        } catch (error) {
            console.error('Error creating mentor account:', error);
        }
    };

    return (
        <div className="bg-gradient-to-b from-blue-400 to-blue-600 min-h-screen">
            <div className="container mx-auto p-8">
                <h1 className="text-3xl text-white font-bold mb-6">Admin - Create Mentor Account</h1>
                <div className="bg-white p-6 rounded shadow-md">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <input className="border p-2 rounded" type="email" name="email" placeholder="Email" value={mentorData.email} onChange={handleChange} />
                        <input className="border p-2 rounded" type="password" name="password" placeholder="Password" value={mentorData.password} onChange={handleChange} />
                    </div>
                    <input className="border p-2 rounded w-full mb-4" type="text" name="name" placeholder="Name" value={mentorData.name} onChange={handleChange} />
                    <textarea className="border p-2 rounded w-full mb-4" name="bio" placeholder="Bio" value={mentorData.bio} onChange={handleChange}></textarea>
                    <input className="border p-2 rounded w-full mb-4" type="text" name="credentials" placeholder="Credentials" value={mentorData.credentials} onChange={handleChange} />
                    <input className="border p-2 rounded w-full mb-4" type="text"
                        name="grade" placeholder="Grade" value={mentorData.grade} onChange={handleChange} />
                    <input className="border p-2 rounded w-full mb-4" type="text" name="skills" placeholder="Skills (separated by commas)" value={mentorData.skills} onChange={handleChange} />
                    <input className="border p-2 rounded w-full mb-6" type="text" name="profilePicture" placeholder="Profile Picture URL" value={mentorData.profilePicture} onChange={handleChange} />
                    <input
                        className="border p-2 rounded w-full mb-4"
                        type="text"
                        name="experienceLevel"
                        placeholder="Experience Level"
                        value={mentorData.experienceLevel}
                        onChange={handleChange}
                    />
                    <button onClick={createMentorAccount} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        Create Mentor Account
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AdminPage;