import React, { useRef, useState, useEffect } from 'react';
import { useAuth } from '../../../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { db } from '../../../firebase';
import { doc, getDoc } from 'firebase/firestore';

export default function Login() {
    const emailRef = useRef();
    const passwordRef = useRef();
    const { login, currentUser } = useAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const getUserRole = async (userId) => {
        let role = null;
        const mentorRef = doc(db, 'mentors', userId);
        const mentorSnap = await getDoc(mentorRef);

        if (mentorSnap.exists()) {
            role = 'mentor';
        } else {
            const menteeRef = doc(db, 'mentees', userId);
            const menteeSnap = await getDoc(menteeRef);

            if (menteeSnap.exists()) {
                role = 'mentee';
            }
        }

        console.log("USER ROLE IS " + role);
        return role;
    };

    useEffect(() => {
        if (currentUser) {
            getUserRole(currentUser.uid)
                .then((role) => {
                    if (role === 'mentor') {
                        navigate('/dashboard');
                    } else if (role === 'mentee') {
                        navigate('/menteedashboard');
                    }
                })
                .catch((error) => {
                    console.error("Error getting user role:", error);
                    setError('Error determining user role');
                });
        }
    }, [currentUser, navigate]);

    async function handleSubmit(e) {
        e.preventDefault();

        try {
            setError('');
            setLoading(true);
            await login(emailRef.current.value, passwordRef.current.value);
        } catch (error) {
            setError('Failed to sign in: ' + error.message);
            setLoading(false);
        }
    }

    return (
        <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-blue-400 to-blue-600">
            <div className="w-full max-w-md">
                <div className="bg-white p-8 rounded shadow-lg">
                    <h2 className="text-3xl font-semibold mb-4 text-center">Login</h2>
                    {error && <div className="bg-red-100 text-red-500 mb-4 p-3 rounded">{error}</div>}

                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label htmlFor="email" className="block text-gray-700 font-bold mb-2">Email</label>
                            <input
                                type="email"
                                id="email"
                                ref={emailRef}
                                className="border border-gray-300 rounded px-4 py-2 w-full focus:outline-none focus:ring focus:border-blue-400"
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="password" className="block text-gray-700 font-bold mb-2">Password</label>
                            <input
                                type="password"
                                id="password"
                                ref={passwordRef}
                                className="border border-gray-300 rounded px-4 py-2 w-full focus:outline-none focus:ring focus:border-blue-400"
                            />
                        </div>

                        <div>
                            <button
                                type="submit"
                                disabled={loading}
                                className="bg-blue-500 text-white rounded px-4 py-2 w-full hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-400"
                            >
                                Login
                            </button>
                        </div>
                    </form>

                    <div className="text-center mt-4">
                        Forgot your password? <Link to="/forgotpassword" className="text-blue-500 hover:underline">Reset</Link>
                    </div>

                    <div className="text-center mt-2">
                        Need an account? <Link to="/signup" className="text-blue-500 hover:underline">Sign Up</Link>
                    </div>
                    <div className="text-center mt-2">
                        <Link to="/" className="text-blue-500 hover:text-blue-700 font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                            Back to Home
                        </Link>
                    </div>

                    
                </div>
            </div>
        </div>
    );
}
