import React from 'react';
import MenteeNavBar from './Components/MenteeNavbar';

const MenteeHelpPage = () => {
  return (
    <div className="bg-gradient-to-br from-blue-500 via-blue-600 to-indigo-700 min-h-screen">
      <MenteeNavBar />
      <div className="container mx-auto text-white p-8">
        <h1 className="text-5xl font-bold text-center mb-12">Mentee Help Center</h1>

        <div className="space-y-12">
          {/* Getting Started Section */}
          <section className="bg-white bg-opacity-10 p-8 rounded-lg shadow-2xl backdrop-blur-sm">
            <h2 className="text-4xl font-semibold mb-6">Getting Started</h2>
            <p className="text-xl mb-4">
              Welcome to MentoMenti! We're thrilled to have you. Start your journey by exploring our vast network of mentors, each with unique skills and experiences. Whether you're looking for guidance in academics, career advice, or skill development, we've got you covered.
            </p>
            <p className="text-xl">
              Navigate through your dashboard to personalize your profile, express your interests, and much more. This is your first step towards an enriching mentoring experience.
            </p>
          </section>

          {/* Finding a Mentor Section */}
          <section className="bg-white bg-opacity-10 p-8 rounded-lg shadow-2xl backdrop-blur-sm">
            <h2 className="text-4xl font-semibold mb-6">Finding a Mentor</h2>
            <p className="text-xl">
              Our "Find Mentors" feature is designed to connect you with mentors that best match your needs. Filter mentors by expertise, credentials, and ratings. Each mentor's profile provides a detailed overview, including their mentoring approach, skills, and reviews from mentees.
            </p>
            <p className="text-xl">
              Don’t hesitate to initiate contact through our secure messaging platform. It's a great way to introduce yourself and discuss how they can help you achieve your goals.
            </p>
          </section>

          {/* Managing Sessions Section */}
          <section className="bg-white bg-opacity-10 p-8 rounded-lg shadow-2xl backdrop-blur-sm">
            <h2 className="text-4xl font-semibold mb-6">Managing Sessions</h2>
            <p className="text-xl">
              With our upcoming session management tool, you'll be able to schedule, reschedule, or cancel mentoring sessions seamlessly. Keep track of your mentoring journey, review past sessions, and plan ahead with an integrated calendar.
            </p>
            <p className="text-xl">
              Stay tuned for updates as we roll out these features to enhance your mentoring experience.
            </p>
          </section>

          {/* FAQs Section */}
          <section className="bg-white bg-opacity-10 p-8 rounded-lg shadow-2xl backdrop-blur-sm">
            <h2 className="text-4xl font-semibold mb-6">Frequently Asked Questions</h2>
            <div className="space-y-6">
              {/* Example FAQ */}
              <div>
                <h3 className="text-2xl font-semibold mb-2">How do I update my profile?</h3>
                <p>Access your dashboard and click on "Edit Profile" to update your personal and academic information. This helps mentors get to know you better!</p>
              </div>
              {/* Add more FAQs as needed */}
              <div>
                <h3 className="text-2xl font-semibold mb-2">What should I do if I can't find a mentor in my field?</h3>
                <p>Try expanding your search criteria or reach out to us for personalized assistance. We're constantly expanding our mentor network and are here to help you connect with the right mentor.</p>
              </div>
            </div>
          </section>

          {/* Need More Help Section */}
          <section className="bg-white bg-opacity-10 p-8 rounded-lg shadow-2xl backdrop-blur-sm">
            <h2 className="text-4xl font-semibold mb-6">Need More Help?</h2>
            <p className="text-xl mb-4">
              Our support team is always here to assist you with any questions or concerns. Whether you need help navigating the platform or have suggestions, we’re all ears.
            </p>
            <a href="/menteecontact" className="inline-block mt-4 bg-blue-600 hover:bg-blue-800 text-white font-bold py-2 px-6 rounded-lg transition duration-200 ease-in-out transform hover:-translate-y-1">
              Contact Support
            </a>
          </section>
        </div>
      </div>
    </div>
  );
};

export default MenteeHelpPage;
