import React from 'react';
import NavBar from './Components/NavBar';
import { Link } from 'react-router-dom'; 

const AboutMentoringPage = () => {
  return (
    <div>
      <NavBar />
      <div className="bg-gradient-to-b from-blue-400 to-blue-600 min-h-screen flex flex-col justify-center">
        
        <div className="max-w-6xl mx-auto text-center p-8">
          <h1 className="text-5xl font-bold text-white mb-8">Why should you mentor?</h1>
          <div className="max-w-6xl mx-auto text-center">
          <div>
            <img
          className="w-[600px] mx-auto md:pr-5 mt-4 w-560 h-360 rounded-full"
          src={'https://i.ibb.co/hLj0ptP/Mentoring-DALL-E.webp'}
          alt="helping"
        />

          </div>

          <p className=" text-gray-200 mb-12">Image generated using DALL-E</p>
        </div>
          <p className="text-xl text-gray-200 mb-12 leading-relaxed">
            Mentoring is a powerful way to share knowledge, experience, and wisdom with others. It's about guiding, inspiring, and offering insights to help others grow in your diverse interests.
          </p>

          {/* Benefits of Being a Mentor */}
          <div className="mt-12">
            <h2 className="text-4xl text-white font-bold mb-6">Why Become a Mentor?</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-3xl font-semibold mb-4 text-blue-600">Share Your Expertise</h3>
                <p className="text-gray-800">
                  As a mentor, you have the opportunity to share your skills, knowledge, and insights with eager learners, helping them to advance in their academic and/or personal development. Sharing your knowledge will empower eager students who are interested in similar passions as you. 
                </p>
                <br/>
                <p>
                You have the chance to provide invaluable personal support to mentees that could boost their progress tremendously. Think about when you first were trying to learn a skill that your now have a strong ability in. You may have not had a mentor to guide you through the mistakes and make the process easier. Or you may have had that helping hand that guided you to where you are now. With MentoMenti you can be that helping hand, that guiding light! Apply to be a mentor today!
                </p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-3xl font-semibold mb-4 text-blue-600">Enhance Leadership Skills and Network</h3>
                <p className="text-gray-800">
                  Mentoring helps in honing your leadership qualities, as it involves guiding others and making impactful decisions. By mentoring other students you will enhance your interpersonal skills. You will build skills such as communication, active listening, empathy, and motivating others. 
                </p>
                <br/>
                <p>
                  You will be able to meet new people and work with them closely. Your network will expand and you could gain great new oppurtunities. Who knows? As a web development enthusiast your web-interested mentee might end up creating a innovative web app -- and lucky you got to connect with them with MentoMenti.
                </p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-3xl font-semibold mb-4 text-blue-600">Meaningful Volunteering Oppurtunity</h3>
                <p className="text-gray-800">
                 As a mentor you will gain an excellent oppurtunity to give back to the community. Alongisde getting to develop core skills like leadership and communication, you will recieve credit on the mentoring you do. If you are an IB student this is the perfect oppurtunity to demonstrate all three strands - Creativity, Activity, and Service. Furthermore, your commitment to our community shows your willingness to work without a monetary compensation, like an intership or job shadow oppurtunity. So, feel free to let others know you are a mentor on MentoMenti -- whether its on your resume or at an interview! 
                </p>
                <br/>
                <p>COMING SOON: The team is working to certify and recognize your mentorship through awards, mastery points, and certificates.</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-3xl font-semibold mb-4 text-blue-600">Strengthen Your Knowledge</h3>
                <p className="text-gray-800">
                  Working with mentees you will share your knowledge in a subject you have a decely strong grasp of already. By teaching mentees, you can gain a deeper understanding of your subject. Studies have shown that students who have been enlisted to tutor others work harder to understand material, recall more accurately, and apply it more effectivley. Scientists call this the "The Protégé Effect"
                </p>
                <br/>
                <br/>
                <b/>
                <p className='font-bold text-2xl tracking-wide'>
                "While we teach, we learn"
                </p>
                <p className=' text-lg tracking-wide'>- Seneca (Roman Philosopher)</p>
              </div>

            </div>
          </div>

          {/* Impact of Mentoring */}
          <div className="mt-12">
            <h2 className="text-4xl text-white font-bold mb-6">The Impact of Mentoring</h2>
            <p className="text-xl text-gray-200 mb-8 leading-relaxed">
              Mentoring can have a transformative effect. It not only benefits the mentee but also enriches the mentor's experience, creating a mutually rewarding relationship.
            </p>

          </div>
          <div className="mt-8">
            <p className="text-white text-xl font-bold mb-4">
              Ready to make a difference? Join our community of mentors and help shape the future of eager learners today!
            </p>
            <Link to="/mentorsignup">
              <button className="text-white bg-blue-700 hover:bg-blue-800 w-[200px] rounded-md font-bold py-3 mb-3 transition duration-300">
                Apply Now
              </button>
              </Link>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMentoringPage;
