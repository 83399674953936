import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; 

const MentorReasonsSection = () => {
  const [visibleReasons, setVisibleReasons] = useState([]);

  const reasonIds = ['mentor-reason1', 'mentor-reason2', 'mentor-reason3', 'mentor-reason4']; // Add more if needed

  const handleScroll = () => {
    const newVisibleReasons = reasonIds.filter((reasonId) => {
      const element = document.getElementById(reasonId);
      if (element) {
        const rect = element.getBoundingClientRect();
        return rect.top >= 0 && rect.bottom <= window.innerHeight;
      }
      return false;
    });

    setVisibleReasons(newVisibleReasons);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check on component mount
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const isReasonVisible = (reasonId) => {
    return visibleReasons.includes(reasonId);
  };

  return (
    <div id="mentor-reasons-section" className="w-full bg-gradient-to-b from-blue-400 to-blue-600 py-16 px-4">
      <div className="max-w-[1240px] mx-auto">
        <div className="text-center">
          <h2 className="text-4xl text-white font-bold mb-8">
            Why Join MentoMenti as a Mentor?
          </h2>
          <div className="grid md:grid-cols-2 gap-8">
            {/* Reason Boxes */}
            <div id="mentor-reason1" className={`transition-opacity duration-700 ${isReasonVisible('mentor-reason1') ? 'opacity-100' : 'opacity-0'}`}>
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-3xl font-semibold mb-4 text-blue-600">Share Your Expertise</h3>
                <p className="text-gray-800">
                As a mentor, you have the opportunity to share your skills, knowledge, and insights with eager learners, helping them to advance in their academic and/or personal development. Sharing your knowledge will empower eager students who are interested in similar passions as you.
                </p>
                <br/>
                <p>
                You have the chance to provide invaluable personal support to mentees that could boost their progress tremendously. Think about when you first were trying to learn a skill that your now have a strong ability in. You may have not had a mentor to guide you through the mistakes and make the process easier. Or you may have had that helping hand that guided you to where you are now. With MentoMenti you can be that helping hand, that guiding light!
                </p>
              </div>
            </div>
            <div id="mentor-reason2" className={`transition-opacity duration-700 ${isReasonVisible('mentor-reason2') ? 'opacity-100' : 'opacity-0'}`}>
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-3xl font-semibold mb-4 text-blue-600">Enhance Your Leadership Skills and Network</h3>
                <p className="text-gray-800">
                Mentoring helps in honing your leadership qualities, as it involves guiding others and making impactful decisions. By mentoring other students you will enhance your interpersonal skills. You will build skills such as communication, active listening, empathy, and motivating others.

                </p>
                <br/>
                <p>
                You will be able to meet new people and work with them closely. Your network will expand and you could gain great new oppurtunities. Who knows? As a web development enthusiast your web-interested mentee might end up creating a innovative web app -- and lucky you got to connect with them with MentoMenti.
                </p>
              </div>
            </div>
            <div id="mentor-reason3" className={`transition-opacity duration-700 ${isReasonVisible('mentor-reason3') ? 'opacity-100' : 'opacity-0'}`}>
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-3xl font-semibold mb-4 text-blue-600">Meaningful Volunteering Oppurtunity</h3>
                <p className="text-gray-800">
                As a mentor you will gain an excellent oppurtunity to give back to the community. Alongisde getting to develop core skills like leadership and communication, you will recieve credit on the mentoring you do. If you are an IB student this is the perfect oppurtunity to demonstrate all three strands - Creativity, Activity, and Service. Furthermore, your commitment to our community shows your willingness to work without a monetary compensation, like an internship or job shadow oppurtunity. So, feel free to let others know you are a mentor on MentoMenti -- whether its on your resume or at an interview!</p>
                <br/>
              </div>
            </div>
            <div id="mentor-reason4" className={`transition-opacity duration-700 ${isReasonVisible('mentor-reason4') ? 'opacity-100' : 'opacity-0'}`}>
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-3xl font-semibold mb-4 text-blue-600">Strengthen Your Knowledge</h3>
                <p className="text-gray-800">
                Working with mentees you will share your knowledge in a subject you have a decely strong grasp of already. By teaching mentees, you can gain a deeper understanding of your subject. Studies have shown that students who have been enlisted to tutor others work harder to understand material, recall more accurately, and apply it more effectivley. Scientists call this the "The Protégé Effect"</p>
                <br/>
                <p className='font-bold text-2xl tracking-wide'>
                "While we teach, we learn"
                </p>
                <p className=' text-lg tracking-wide'>- Seneca (Roman Philosopher)</p>
                
              </div>
              
            </div>
            

          </div>
          <div className="mt-8">
            <p className="text-white text-3xl font-bold mb-4">
              Ready to make a difference? Join our community of mentors and help shape the future of eager learners today!
            </p>
            <Link to="/mentorsignup">
              <button className="text-white bg-blue-600 hover:bg-blue-700 w-[200px] rounded-md font-bold py-3 transition duration-300">
                Apply Now
              </button>
              </Link>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default MentorReasonsSection;
