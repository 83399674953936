import React, { useState, useEffect } from 'react';
import MentorNavBar from './Components/MentorNavBar';
import { useAuth } from '../context/AuthContext';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate,Link } from 'react-router-dom';


const MentorDashboard = () => {
  const [mentorData, setMentorData] = useState(null);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      const fetchMentorData = async () => {
        try {
          const docRef = doc(db, 'mentors', currentUser.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            setMentorData(docSnap.data());
          } else {
            console.error('Not a mentor or mentor not found');
            navigate('/');
          }
        } catch (error) {
          console.error('Error fetching mentor data:', error);
        }
      };

      fetchMentorData();
    }
  }, [currentUser, navigate]);

  if (!mentorData) {
    return <div className="text-white text-center">Loading...</div>;
  }

  return (
    <div className="bg-gradient-to-br from-cyan-500 to-blue-700 min-h-screen">
      <MentorNavBar />
      <div className="max-w-6xl mx-auto p-8">
        <div className="text-white">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">

            <div className="bg-white bg-opacity-20 backdrop-blur-md rounded-lg shadow-xl p-6 text-center">
              <img src={mentorData.profilePicture || 'https://via.placeholder.com/150'} alt="Mentor" className="w-32 h-32 object-cover rounded-full mx-auto mb-4 shadow-lg" />
              <h2 className="text-3xl font-bold mb-2">{mentorData.name}</h2>
              <p className="text-lg mb-4">{mentorData.about}</p>
              <div className="mt-4">
                <h3 className="text-2xl font-semibold mb-2">My Mentees</h3>
                {/* Dynamic content for mentees */}
                <div className='pt-5  text-center'>
            <Link to="/findmentees" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-full text-lg transition duration-300 ease-in-out transform hover:scale-105">
      Find Mentees
    </Link>
            </div>
              </div>
            </div>

            <div className="md:col-span-2 space-y-6">
              <div className="bg-white bg-opacity-20 backdrop-blur-md rounded-lg shadow-xl p-6">
                <h1 className="text-4xl font-bold mb-4">Dashboard Overview</h1>
                <p className="text-xl mb-4">Welcome to your mentor dashboard. Here's an overview of your mentoring activities.</p>
                <div className="flex justify-around">
                  <div className="text-center">
                    <span className="text-3xl font-bold">{mentorData.grade}</span>
                    <p className="text-lg">Grade</p>
                  </div>
                  <div className="text-center">
                    <span className="text-3xl font-bold">{mentorData.sessions}</span>
                    <p className="text-lg">Sessions</p>
                  </div>
                  <div className="text-center">
                    <span className="text-3xl font-bold">{mentorData.mentees}</span>
                    <p className="text-lg">Mentees</p>
                  </div>
                </div>
              </div>

              <div className="bg-white bg-opacity-20 backdrop-blur-md rounded-lg shadow-xl p-6">
                <h2 className="text-3xl font-semibold mb-4">Your Calendar</h2>
                <p className="text-xl mb-4">Google Calendar integration is coming soon for easier scheduling.</p>

                <div className="border border-gray-300 rounded-md p-4 h-48">
                  <p className="text-center text-lg">Calendar Placeholder</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default MentorDashboard;
