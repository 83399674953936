import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { db } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import MentorNavBar from './Components/MentorNavBar';
import ProfilePictureHelpModal from './PfpModal';

const EditMentorProfile = () => {
  const { currentUser } = useAuth();
  const [mentorData, setMentorData] = useState({
    name: '',
    bio: '',
    credentials: '',
    skills: '',
    profilePicture: 'https://via.placeholder.com/150',
  });
  const [showHelpModal, setShowHelpModal] = useState(false);

  useEffect(() => {
    const fetchMentorData = async () => {
      try {
        const docRef = doc(db, "mentors", currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setMentorData({
            ...data,
            skills: data.skills ? data.skills.join(', ') : ''
          });
        } else {
          console.log("No such mentor document!");
        }
      } catch (error) {
        console.error("Error fetching mentor data:", error);
      }
    };

    fetchMentorData();
  }, [currentUser.uid]);

  const handleChange = (e) => {
    setMentorData({ ...mentorData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedSkills = mentorData.skills.split(',').map(skill => skill.trim());
    const updatedData = {
      ...mentorData,
      skills: updatedSkills,
    };
    const mentorRef = doc(db, "mentors", currentUser.uid);

    try {
      await updateDoc(mentorRef, updatedData);
      alert("Profile updated successfully");
    } catch (error) {
      console.error("Error updating mentor document: ", error);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-400 via-pink-500 to-red-500">
      <MentorNavBar />
      <div className="container mx-auto p-8">
        <h1 className="text-4xl font-bold mb-8 text-center text-white">Edit Mentor Profile</h1>
        <div className="max-w-4xl mx-auto bg-white p-8 rounded-xl shadow-xl">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="mb-4">
              <label htmlFor="name" className="block text-gray-700 font-bold mb-2">Name:</label>
              <input type="text" id="name" name="name" value={mentorData.name} onChange={handleChange} className="w-full px-3 py-2 border rounded text-gray-700" required />
            </div>

            <div className="mb-4">
              <label htmlFor="bio" className="block text-gray-700 font-bold mb-2">Bio:</label>
              <textarea id="bio" name="bio" value={mentorData.bio} onChange={handleChange} className="w-full px-3 py-2 border rounded text-gray-700" required></textarea>
            </div>

            <div className="mb-4">
              <label htmlFor="credentials" className="block text-gray-700 font-bold mb-2">Credentials:</label>
              <input type="text" id="credentials" name="credentials" value={mentorData.credentials} onChange={handleChange} className="w-full px-3 py-2 border rounded text-gray-700" required />
            </div>

            <div className="mb-4">
              <label htmlFor="skills" className="block text-gray-700 font-bold mb-2">Skills (separated by commas):</label>
              <input type="text" id="skills" name="skills" value={mentorData.skills} onChange={handleChange} className="w-full px-3 py-2 border rounded text-gray-700" required />
            </div>

            <div className="mb-4">
              <label htmlFor="profilePicture" className="block text-gray-700 font-bold mb-2">Profile Picture URL:</label>
              <div className="flex">
                <input type="text" id="profilePicture" name="profilePicture" value={mentorData.profilePicture} onChange={handleChange} className="w-full px-3 py-2 border rounded-full object-cover text-gray-700" required />
                <button
                  type="button"
                  onClick={() => setShowHelpModal(true)}
                  className="ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Help
                </button>
              </div>
              <img src={mentorData.profilePicture || 'https://via.placeholder.com/150'} alt="Profile" className="w-32 h-32 rounded-full mx-auto mt-4" />
            </div>

            <div className="flex justify-center mt-6">
              <button type="submit" className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-lg transition duration-200 ease-in-out transform hover:-translate-y-1 shadow">
                Update Profile
              </button>
            </div>
          </form>
        </div>
      </div>

      <ProfilePictureHelpModal showHelpModal={showHelpModal} setShowHelpModal={setShowHelpModal} />
    </div>
  );
};

export default EditMentorProfile;
