import React from 'react'
import Home from './pages/Home/Home';
import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth'; 
import { getAuth } from "firebase/auth";
import SignUpPage from './pages/Home/Sign Up/SignUp';
import LoginPage from './pages/Home/Sign Up/LoginPage';
import AuthProvider from './context/AuthContext';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProfilePage from './pages/Profile';
import DashboardPage from './pages/Dashboard';
import FindMentorsPage from './pages/FindMentors';
import MentorSignUpForm from './pages/Home/Sign Up/MentorSignUp';
import MentorProfileForm from './pages/Home/Sign Up/MentorProfileForm';
import MenteeDashboard from './pages/MenteeDashboard';
import PrivateRoutes  from './components/PrivateRoutes';
import AboutPage from './pages/About';
import ContactPage from './pages/contact';
import MentorProfile from './pages/MentorProfile';
import InformationPage from './pages/MentorInfo';
import MenteeInfoPage from './pages/MenteeInfo';
import AboutMentoringPage from './pages/AboutMentor';
import EditProfilePage from './pages/EditMentorPage';
import EditMenteeProfile from './pages/EditMenteeProfile';
import AdminProtectedRoute from './pages/Components/AdminProtectedRoute';
import AdminPage from './pages/admin';
import MenteeHelpPage from './pages/MenteeHelp';
import AboutMenteesPage from './pages/AboutMentee';
import FindMenteesPage from './pages/FindMentees';
import MentorHelpPage from './pages/MentorHelp';
import MenteeProfile from './pages/MenteeProfile';
import PasswordReset from './pages/Home/Sign Up/PasswordReset';
import About from './pages/Home/About';
import AdminMentorPage from './pages/AdminMentorApprov';
import MentorApplicationForm from './pages/MentorForm';
import FindOtherMentorsPage from './pages/MentorConnect';
import AdminApprovalPage from './pages/AdminMentorApprov';
import MentorConnectProfile from './pages/MentorConnectProfile';
import MentorConnectPage from './pages/MentorConnect';
import ContactMentorPage from './pages/MentorContact';
import ContactMenteePage from './pages/MenteeContact';
import PoliciesPage from './pages/PoliciesPage';








function App() {
  return (
      <div>
        
     <div>
      <AuthProvider>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route path='/signup' element={<SignUpPage/>} />
          <Route path='/login' element={<LoginPage/>} />
          <Route path='/contact' element={<ContactPage/>} />


          
          
          {/* <Route path='/mentorsignup' element={<MentorSignUpForm/>} /> */}

          <Route path="/mentorinfo" element={<InformationPage />} />
          <Route path="/menteeinfo" element={<MenteeInfoPage />} />
          <Route path="/aboutmentors" element={<AboutMentoringPage />} />
          <Route path="/aboutmentees" element={<AboutMenteesPage />} />
          <Route path='/about' element={<AboutPage/>} />
          <Route path="/forgotpassword" element={<PasswordReset />} />
          <Route path="/mentorsignup" element={<MentorApplicationForm />} /> 
          <Route path="/terms" element={<PoliciesPage />} /> 
            

          

          //PrivateRoutes for mentors
          <Route element={<PrivateRoutes allowedRoles={['mentor']} />}>
          <Route path='/dashboard' element={<DashboardPage/>} />
          <Route path='/profile' element={<ProfilePage/>} />
          <Route path='/findmentees' element={<FindMenteesPage/>} />
          <Route path='/mentorhelp' element={<MentorHelpPage/>} />
          <Route path="/editmentor" element={<EditProfilePage />} />
          <Route path="/mentee/:id" element={<MenteeProfile />} />
          <Route path="/mentorconnect/:id" element={<MentorConnectProfile/>} />
          <Route path="/mentorcontact" element={<ContactMentorPage/>} />
          <Route path="/mentorconnect" element={<FindOtherMentorsPage />} />
          {/* <Route path="/mentor/:id" element={<MentorProfile />} /> */}
          </Route>
          
          //Private routes for mentees
          <Route element={<PrivateRoutes allowedRoles={['mentee']} />}>
          <Route  path='/MenteeDashboard' element={<MenteeDashboard/>}/>
          <Route path='/findmentors' element={<FindMentorsPage/>} />
          <Route path='/menteehelp' element={<MenteeHelpPage/>} />
          <Route path="/editmentee" element={<EditMenteeProfile />} />
          <Route path="/mentor/:id" element={<MentorProfile />} />
          <Route path="/menteecontact" element={<ContactMenteePage />} />
          </Route>

          {/* Admin route protected */}
    <Route path='/admin' element={
      <AdminProtectedRoute>
        <AdminPage />
      </AdminProtectedRoute>
    } />
     <Route path='/approvals' element={
      <AdminProtectedRoute>
        <AdminApprovalPage/>
      </AdminProtectedRoute>
    } />


          {/* <PrivateRoute exact path='/MenteeDashboard' element={<MenteeDashboard/>} /> OLD React router*/}

          

        </Routes>
      </AuthProvider>
     </div>
     

    </div>

   
    
  );
}

export default App;
