import React from 'react';


const MentorProfilePage = () => {
  
  const mentor = {
    name: 'John Doe',
    skills: ['Web Development', 'React', 'JavaScript'],
    bio: 'Passionate about web development and helping others succeed.',
    credentials: 'M.Sc. in Computer Science',
    sessions: 32,
    mentees: 18,
    hoursMentored: 150,
    experienceLevel: 'Expert',
    profilePicture: 'https://via.placeholder.com/150', 
    mentorshipRequests: [
      
    ],
  };

  return (
    <div className="flex flex-col h-screen bg-white">
      <div className="flex-1 flex overflow-hidden">
        {/* Left Section - Profile Picture and Stats Grid */}
        <div className="flex flex-col flex-shrink-0 w-70 p-12 px-5 border-r border-gray-200">
          <div className="flex items-center justify-center mb-6">
            <img src={mentor.profilePicture} alt={mentor.name} className="w-32 h-32 rounded-full " />
          </div>
          <div className="grid grid-cols-2 gap-6">
            <div className="p-6 bg-white shadow-md rounded-lg">
              <h2 className="text-xl font-semibold">Sessions</h2>
              <p className="text-3xl font-bold text-center w-full">{mentor.sessions}</p>
            </div>
            <div className="p-6 bg-white shadow-md rounded-lg">
              <h2 className="text-xl font-semibold">Mentees</h2>
              <p className="text-3xl font-bold text-center w-full">{mentor.mentees}</p>
            </div>
            <div className="p-6 bg-white shadow-md rounded-lg">
              <h2 className="text-xl font-semibold">Hours Mentored</h2>
              <p className="text-3xl font-bold text-center w-full">{mentor.hoursMentored}</p>
            </div>
            <div className="p-6 bg-white shadow-md rounded-lg">
              <h2 className="text-xl font-semibold">Experience</h2>
              <p className="text-3xl font-bold text-center w-full">{mentor.experienceLevel}</p>
            </div>
          </div>
        </div>

        {/* Right Section - Mentor Information */}
        <div className="flex-1 overflow-y-auto p-10">
          <h1 className="text-4xl font-semibold pb-4">{mentor.name}</h1>
          <p className="text-gray-600 text-lg">Skills: {mentor.skills.join(', ')}</p>
          <p className="text-gray-700 text-lg">{mentor.bio}</p>
          <p className="text-gray-600 my-4 text-lg">Credentials: {mentor.credentials}</p>

          {/* Mentorship Requests */}
          <div className="bg-white shadow-md rounded-lg p-4 my-6">
            <h2 className="text-lg font-semibold mb-4">Mentorship Requests</h2>
            {mentor.mentorshipRequests.length > 0 ? (
              mentor.mentorshipRequests.map((request) => (
                <div key={request.id} className="border-b border-gray-300 py-2">
                  {/* Display mentorship request details */}
                </div>
              ))
            ) : (
              <p className="text-gray-600">No mentorship requests yet.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MentorProfilePage;
