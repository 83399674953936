import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import MenteeNavBar from './Components/MenteeNavbar'; 

const MentorProfile = () => {
  const { id } = useParams();
  const [mentorData, setMentorData] = useState(null);

  useEffect(() => {
    const fetchMentorData = async () => {
      try {
        const mentorRef = doc(db, "mentors", id);
        const mentorSnapshot = await getDoc(mentorRef);

        if (mentorSnapshot.exists()) {
          setMentorData(mentorSnapshot.data());
        } else {
          console.error("Mentor not found");
        }
      } catch (error) {
        console.error("Error fetching mentor data:", error);
      }
    };

    fetchMentorData();
  }, [id]);

  if (!mentorData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-gradient-to-br from-cyan-500 to-blue-700 min-h-screen">
      <MenteeNavBar />
      <div className="container mx-auto p-10 text-white">
        <div className="bg-white bg-opacity-10 backdrop-blur-lg rounded-lg shadow-xl p-8 text-center md:text-left space-y-6">
          <div className="flex flex-col md:flex-row md:items-center space-y-6 md:space-y-0 md:space-x-10">
            <img src={mentorData.profilePicture || 'https://via.placeholder.com/150'} alt={mentorData.name} className="w-48 h-48 rounded-full shadow-lg object-cover object-center mx-auto md:mx-0" />
            <div className="flex-1 space-y-4">
              <h1 className="text-4xl font-bold">{mentorData.name}</h1>
              <p className="text-xl text-blue-100">{mentorData.email}</p>
              <p className="text-xl text-blue-200">{mentorData.expertise}</p>
              <p className="text-lg">{mentorData.bio}</p>
            </div>
          </div>

          <div className="space-y-4">
            <h2 className="text-3xl font-semibold">Credentials & Achievements</h2>
            <p className="text-xl">{mentorData.credentials}</p>
          </div>

          <div className="space-y-4">
            <h2 className="text-3xl font-semibold">Skills</h2>
            <div className="flex flex-wrap justify-center gap-4">
              {mentorData.skills.map((skill, index) => (
                <span key={index} className="bg-blue-600 bg-opacity-80 text-white py-2 px-4 rounded-full text-lg shadow-md">
                  {skill}
                </span>
              ))}
            </div>
          </div>
          <div className="mt-8">
            <h2 className="text-2xl font-semibold">Mentor's Note on Availability</h2>
            <p className="mt-4 text-lg">I am flexible with my availability. Please reach out to discuss a suitable time for both of us.</p>
            <p className="italic mt-2">Note: A Google Calendar availability tracker will be added soon for easier scheduling.</p>
          </div>

          <div className="flex flex-wrap justify-center pt-10">
            <a 
              href={`mailto:${mentorData.email}?subject=MentoMenti - Mentorship Request&body=Dear ${mentorData.name},%0D%0A%0D%0AI am interested in your mentorship in (eg. Programming). I am keen on learning more about ______. [Please add more personalized message here.]%0D%0A%0D%0ABest regards,%0D%0A[Your Name]`}
              className="inline-block bg-blue-600 hover:bg-blue-800 text-white font-bold py-3 px-8 rounded-full text-xl transition duration-200 ease-in-out transform hover:-translate-y-1 shadow-lg"
            >
              Reach Out via Email
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MentorProfile;
