import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';
import ProfilePictureHelpModal from './PfpModal';

const MentorApplicationForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        bio: '',
        skills: '',
        credentials: '',
        profilePicture: '',
        password: '',
        grade: '',
        goodMentorReason: ''
    });
    const [showHelpModal, setShowHelpModal] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const applicationData = {
                ...formData,
                skills: formData.skills.split(',').map(skill => skill.trim()),
                approved: false
            };

            await addDoc(collection(db, "mentorApplications"), applicationData);
            alert('Application submitted successfully. Please wait for approval.');
            navigate('/');
        } catch (error) {
            console.error('Error submitting application:', error);
            alert('Error submitting application.');
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-r from-blue-500 to-indigo-500">
            <div className="container mx-auto p-8">
                <h2 className="text-3xl font-bold text-center text-white mb-4">Mentor Application</h2>
                <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                    {/* Name field */}
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                            Full Name
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="name"
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Your full name"
                            required
                        />
                    </div>

                    {/* Email field */}
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                            Email
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="email"
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Your email"
                            required
                        />
                    </div>

                    {/* Bio field */}
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="bio">
                            Bio
                        </label>
                        <textarea
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="bio"
                            name="bio"
                            value={formData.bio}
                            onChange={handleChange}
                            placeholder="Brief bio about yourself"
                            required
                        />
                    </div>

                    {/* Skills field */}
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="skills">
                            Skills
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="skills"
                            type="text"
                            name="skills"
                            value={formData.skills}
                            onChange={handleChange}
                            placeholder="Your skills (comma separated -- eg. Public Speaking, AP Calculus AB, Physics, Essay Writing)"
                            required
                        />
                    </div>

                    {/* Credentials field */}
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="credentials">
                            Credentials
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="credentials"
                            type="text"
                            name="credentials"
                            value={formData.credentials}
                            onChange={handleChange}
                            placeholder="Your credentials... (eg. AP World History 5/5, Science Excellence Award)"
                            required
                        />
                    </div>

                    {/* Grade Level field */}
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="gradeLevel">
                            Grade Level
                        </label>
                        <select
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="grade"
                            name="grade"
                            value={formData.grade}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select your grade level</option>
                            <option value="9">Grade 9</option>
                            <option value="10">Grade 10</option>
                            <option value="11">Grade 11</option>
                            <option value="12">Grade 12</option>
                        </select>

                    </div>

                    {/* Good Mentor Reason field */}
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="goodMentorReason">
                            Why would you be a good mentor? (1500 characters max)
                        </label>
                        <textarea
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="goodMentorReason"
                            name="goodMentorReason"
                            value={formData.goodMentorReason}
                            onChange={handleChange}
                            maxLength="1500"
                            placeholder="Explain in 1500 characters (about 300 words) or less why you would be a good mentor"
                            required
                        />
                    </div>

                    {/* Profile Picture field */}
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="profilePicture">
                            Profile Picture URL
                        </label>
                        <div className="flex">
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="profilePicture"
                                type="text"
                                name="profilePicture"
                                value={formData.profilePicture}
                                onChange={handleChange}
                                placeholder="Link to your profile picture"
                                required
                            />
                            <button
                                type="button"
                                onClick={() => setShowHelpModal(true)}
                                className="ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            >
                                Help
                            </button>
                        </div>
                    </div>

                    {/* Password field */}
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                            Password
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="password"
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            placeholder="Set your password"
                            required
                        />
                    </div>

                    <div className="flex items-center justify-between">
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="submit"
                        >
                            Apply
                        </button>
                        <button
                            type="button"
                            onClick={() => navigate('/')}
                            className="text-blue-500 hover:text-blue-700 font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                            Back to Home
                        </button>
                    </div>
                </form>

                {<ProfilePictureHelpModal showHelpModal={showHelpModal} setShowHelpModal={setShowHelpModal} />
                }
            </div>
        </div>
    );
};

export default MentorApplicationForm;
