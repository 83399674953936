import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const ReasonsSection = () => {
  const [visibleReasons, setVisibleReasons] = useState([]);

  const reasonIds = ['reason1', 'reason2', 'reason3', 'reason4']; // Add more if needed

  const handleScroll = () => {
    const newVisibleReasons = reasonIds.filter((reasonId) => {
      const element = document.getElementById(reasonId);
      if (element) {
        const rect = element.getBoundingClientRect();
        return rect.top >= 0 && rect.top <= window.innerHeight * 0.8; // 80% of the viewport height
      }
      return false;
    });

    setVisibleReasons(newVisibleReasons);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check on component mount
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const isReasonVisible = (reasonId) => {
    return visibleReasons.includes(reasonId);
  };

  return (
    <div id="reasons-section" className="w-full bg-gradient-to-b from-blue-400 to-blue-600 py-16 px-4">
      <div className="max-w-[1240px] mx-auto">
        <div className="text-center">
          <h2 className="text-4xl text-white font-bold mb-8">
            Why Join MentoMenti as a mentee?
          </h2>
          <div className="grid md:grid-cols-2 gap-8">
            {/* Reason Boxes */}
            <div id="reason1" className={`transition-opacity duration-700 ${isReasonVisible('reason1') ? 'opacity-100' : 'opacity-0'}`}>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-3xl font-semibold mb-4 text-blue-600">Personalized Guidance</h3>
              <p className="text-gray-800">
              Every learning journey is unique, and personalized guidance can significantly impact your progress. Mentors on MentoMenti will take the time to understand your specific goals, challenges, and learning preferences. This tailored approach ensures that you receive support that is most relevant and effective for you, helping you overcome obstacles more efficiently and achieving your goals faster. Having a mentor will help you decrease the amount of your valuable time often wasted when independetly learning.           </p>
            </div>
            </div>
            <div id="reason2" className={`transition-opacity duration-700 ${isReasonVisible('reason2') ? 'opacity-100' : 'opacity-0'}`}>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-3xl font-semibold mb-4 text-blue-600">Expand Your Network</h3>
              <p className="text-gray-800">
              Joining MentoMenti as a mentee opens up numerous networking opportunities. Our community consists of enthusiatic students and with various diverse interests and expertise, offering you the chance to connect with individuals who share your interests and can introduce you to new perspectives and opportunities. Networking with mentors and fellow mentees can lead to lasting relationships possible collaborations and even job/internship oppurtunities.             </p>
            </div>
            </div>
            <div id="reason3" className={`transition-opacity duration-700 ${isReasonVisible('reason3') ? 'opacity-100' : 'opacity-0'}`}>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-3xl font-semibold mb-4 text-blue-600">Real World Insights and Experience</h3>
              <p className="text-gray-800 pb-10">
              Beyond academic knowledge, having a mentor with real-world experience, insights, and wisdom in your area of interest is invaluable. Mentors on MentoMenti are experienced students who bring practical insights in their expertise into their mentoring. They can share lessons learned from their careers, offer advice on navigating the discipline, and provide feedback on projects or ideas. This exposure to real-world wisdom prepares you for challenges outside the classroom or textbook. These experienced mentors insights can be crucial to your growth in your interest area.             </p>
            </div>
            </div>
            <div id="reason4" className={`transition-opacity duration-700 ${isReasonVisible('reason4') ? 'opacity-100' : 'opacity-0'}`}>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-3xl font-semibold mb-4 text-blue-600">Boost Confidence and Motivation
</h3>
              <p className="text-gray-800">
              Having a mentor can significantly boost your confidence and motivation. Our mentors encourage you to set challenging yet achievable goals, celebrate your successes, and help you learn from failures. This supportive environment fosters a positive mindset and resilience, essential qualities for personal and academic growth.             </p>
              <br/>
                <p className='font-bold text-xl tracking-wide'>
                "Our chief want in life is somebody who will make us do what we can."
                </p>
                <p className=' text-lg pt-2 '>- Ralph Waldo Emerson</p>
                
            </div>
            </div>
            {/* Add more reason divs with corresponding ids */}
          </div>
          <div className="mt-8">
            <p className="text-white text-3xl font-bold mb-4">
            Embark on your learning journey with us. Gain access to personalized guidance and accelerate your growth. Sign up today and connect with mentors ready to guide you!            </p>
            <Link to="/signup">
              <button className="text-white bg-blue-600 hover:bg-blue-700 w-[200px] rounded-md font-bold py-3 transition duration-300">
                Sign Up Now
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReasonsSection;
