import React from 'react';
import NavBar from './Components/NavBar';
import { Link } from 'react-router-dom'; 

const AboutMenteesPage = () => {
  return (
    <div>
      <NavBar />
      <div className="bg-gradient-to-b from-blue-400 to-blue-600 min-h-screen flex flex-col justify-center">
        
        <div className="max-w-6xl mx-auto text-center p-8">
          <h1 className="text-5xl font-bold text-white mb-8">Why Become a Mentee?</h1>
          <div className="max-w-6xl mx-auto text-center">
          <div>
            <img
          className="w-[400px] mx-auto md:pr-5 mt-4 w-560 h-360 rounded-full"
          src={'https://i.ibb.co/TWHtyTy/DALL-E-Image.webp'}
          alt="learning"
        />

          </div>
          {/* Caption Text */}
          <p className=" text-gray-200 mb-12">Image generated using DALL-E</p>
        </div>
          <p className="text-xl text-gray-200 mb-12 leading-relaxed">
            Becoming a mentee opens the door to a wealth of knowledge, experience, and personalized guidance. It's about receiving tailored advice, accelerating your learning, and making meaningful connections.
          </p>

          {/* Benefits of Being a Mentee */}
          <div className="mt-12">
            <h2 className="text-4xl text-white font-bold mb-6">Benefits of Being a Mentee</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-3xl font-semibold mb-4 text-blue-600">Personalized Guidance</h3>
                <p className="text-gray-800">
                  Gain insights and advice tailored specifically to your needs and goals. Mentors provide one-on-one support to help you navigate challenges and make informed decisions.
                </p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-3xl font-semibold mb-4 text-blue-600">Accelerated Learning</h3>
                <p className="text-gray-800">
                  Learn from the experiences and mistakes of others. Mentors can provide shortcuts to knowledge and skills that would otherwise take years to acquire on your own.
                </p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-3xl font-semibold mb-4 text-blue-600">Expand Your Network</h3>
                <p className="text-gray-800">
                  Mentors can introduce you to a professional network that might be difficult to access otherwise. These connections can open doors to opportunities and resources.
                </p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-3xl font-semibold mb-4 text-blue-600">Boost Your Confidence</h3>
                <p className="text-gray-800">
                  Having a mentor believe in you can significantly boost your confidence. This support can empower you to take on new challenges and strive for your goals.
                </p>
              </div>

            </div>
          </div>

          {/* Impact of Being a Mentee */}
          <div className="mt-12">
            <h2 className="text-4xl text-white font-bold mb-6">The Impact of Being a Mentee</h2>
            <p className="text-xl text-gray-200 mb-8 leading-relaxed">
              Being a mentee not only accelerates your personal and professional growth but also enriches your journey with insights, confidence, and a strong support system.
            </p>

          </div>
          <div className="mt-8">
            <p className="text-white text-xl font-bold mb-4">
            Embark on your learning journey with us. Gain access to personalized guidance and accelerate your growth. Sign up today and connect with mentors ready to guide you!            </p>
            <Link to="/signup">
              <button className="text-white bg-blue-700 hover:bg-blue-800 w-[200px] rounded-md font-bold py-3 transition duration-300">
                Sign Up Now
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMenteesPage;
