import React from 'react';

const ProfilePictureHelpModal = ({ showHelpModal, setShowHelpModal }) => {
    if (!showHelpModal) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center">
            <div className="bg-white p-4 rounded-lg max-w-2xl w-full max-h-[80vh] overflow-y-auto">
                <h2 className="text-xl font-bold mb-4">How to Update Your Profile Picture</h2>
                <p className="mb-4">MentoMenti is currently in beta - Direct image upload is coming soon. Follow these steps to update your profile picture:</p>
                <ol className="list-decimal list-inside mb-4">
                    <li>Go to an image hosting website like <a href="https://postimages.org/" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-600">PostImages</a>.</li>
                    <li>Under the default settings upload a square image of yourself and copy the "Direct Link" to the image.</li>
                    <li>Paste the link in the profile picture URL field on your profile page.</li>
                    <li>Save your profile to update the picture.</li>
                </ol>
                <div className="my-4">
                    <p className="font-bold">Example:</p>
                    <img src="https://i.postimg.cc/2jPxLS2p/Screenshot-2024-04-06-at-7-40-31-PM.png" alt="Upload Step 1" className="my-2"/>
                    <img src="https://i.postimg.cc/5t0zkhnq/Screenshot-2024-04-06-at-7-42-04-PM.png" alt="Upload Step 2" className="my-2"/>
                </div>
                <button
                    onClick={() => setShowHelpModal(false)}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default ProfilePictureHelpModal;
