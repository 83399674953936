import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';

const MentorNavBar = () => {
  const [nav, setNav] = useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleNav = () => {
    setNav(!nav);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <nav className="bg-blue-800 p-4 sticky top-0 z-50">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/dashboard" className="flex items-center text-white text-2xl font-semibold">
          <img src="https://i.ibb.co/RywmkKn/Whats-App-Image-2024-03-19-at-19-53-06.png" alt="MentoMenti Logo" className="mr-2 h-8" />
          MentorMenti - MentorZone
        </Link>
        <div className="hidden md:flex items-center space-x-6">
          <Link to="/dashboard" className="text-white hover:text-blue-200">Dashboard</Link>
          <Link to="/findmentees" className="text-white hover:text-blue-200">Find Mentees</Link>
          <Link to="/mentorconnect" className="text-white hover:text-blue-200">Mentor Connect</Link>
          <Link to="/editmentor" className="text-white hover:text-blue-200">Edit Profile</Link>
          <Link to="/mentorhelp" className="text-white hover:text-blue-200">Help</Link>
          <button onClick={handleLogout} className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">Logout</button>
        </div>
        <div onClick={handleNav} className="md:hidden">
          {nav ? <AiOutlineClose size={20} className="text-white" /> : <AiOutlineMenu size={20} className="text-white" />}
        </div>
      </div>

      {/* Mobile Menu */}
      <div className={nav ? 'md:hidden fixed left-0 top-[66px] w-full bg-blue-800 shadow-md' : 'hidden'}>
        <ul className="uppercase p-4">
          <li className="p-4 border-b border-blue-600">
            <Link to="/dashboard" onClick={() => setNav(false)}>Dashboard</Link>
          </li>
          <li className="p-4 border-b border-blue-600">
            <Link to="/findmentees" onClick={() => setNav(false)}>Find Mentees</Link>
          </li>
          <li className="p-4 border-b border-blue-600">
            <Link to="/editmentor" onClick={() => setNav(false)}>Edit Profile</Link>
          </li>
          <li className="p-4 border-b border-blue-600">
            <Link to="/mentorconnect" onClick={() => setNav(false)}>Mentor Connect</Link>
          </li>
          <li className="p-4 border-b border-blue-600">
            <Link to="/mentorhelp" onClick={() => setNav(false)}>Help</Link>
          </li>
          <li className="p-4">
            <button onClick={handleLogout} className="text-white font-bold py-2 px-4 rounded">Logout</button>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default MentorNavBar;
