import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const allowedAdminUIDs = ['wrI8u9sJMLZv2q8VDOBLXnlECE42', '3RNkhLyeOyON80CZXozjtns70RH2', 'vbKPva4UWOWtss0bYiohNwsHwps1', 'UjuytwRlvdQL7w1aBSWEvMbWb3B2', 'e52HhglgQJX3NkWrFZKwNxSsDkD2', "GhpNnVm1hBX81LCO9KiCMm84SJR2", "VXj3zA1Z13TkQzvXNoKqaXMMMK63"]; // Replace with actual UIDs

const AdminProtectedRoute = ({ children }) => {
    const { currentUser } = useAuth();
    const [isAllowed, setIsAllowed] = useState(false);

    useEffect(() => {
        if (currentUser && allowedAdminUIDs.includes(currentUser.uid)) {
            setIsAllowed(true);
        }
    }, [currentUser]);

    if (!currentUser) {
        return <Navigate to="/login" />;
    }

    if (!isAllowed) {
        return <div className='text-lg' >What you looking for huh?</div>; // Or redirect to another page
    }

    return children;
};

export default AdminProtectedRoute;
