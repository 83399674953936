import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const MenteeNavBar = () => {
    const [nav, setNav] = useState(false);
    const { logout } = useAuth();
    const navigate = useNavigate();

    const handleNav = () => {
        setNav(!nav);
    };

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/login');
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    return (
        <nav className="bg-blue-800 p-4 sticky top-0 z-50">
            <div className="container mx-auto flex justify-between items-center">
                <Link to="/menteedashboard" className="flex items-center text-white text-2xl font-semibold">
                    <img src="https://i.ibb.co/RywmkKn/Whats-App-Image-2024-03-19-at-19-53-06.png" alt="MentoMenti Logo" className="mr-2 h-8" />
                    MentoMenti
                </Link>

                <div className="hidden md:flex items-center space-x-6">
                    <Link to="/menteedashboard" className="text-white hover:text-blue-200">Dashboard</Link>
                    <Link to="/findmentors" className="text-white hover:text-blue-200">Find Mentors</Link>
                    <Link to="/editmentee" className="text-white hover:text-blue-200">Edit Profile</Link>
                    <Link to="/menteehelp" className="text-white hover:text-blue-200">Help</Link>

                    <button onClick={handleLogout} className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                        Logout
                    </button>
                </div>

                <div onClick={handleNav} className="md:hidden">
                    {nav ? (
                        <AiOutlineClose size={20} className="text-white" />
                    ) : (
                        <AiOutlineMenu size={20} className="text-white" />
                    )}
                </div>
            </div>
        </nav>
    );
};

export default MenteeNavBar;
